<template>
  <v-pagination
    class="project-pagination"
    color="var(--v-blue-base)"
    v-model="page"
    :length="totalPage"
    @input="page => this.choosePage(page)"
  />
</template>
<script>
export default {
  name: 'pagination',
  props: {
    currentTotalPage: {
      type: Number,
    },
    limit: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      page: 1,
      totalPage: 1,
      offset: 0,
    };
  },
  mounted() {
    this.totalPage = this.currentTotalPage;
  },
  watch: {
    currentTotalPage(/*oldValue, newValue*/) {
      // so don't understand !! why ?
      this.totalPage = this.currentTotalPage;
    },
  },
  methods: {
    choosePage(page) {
      this.page = page;
      this.offset = this.limit * (this.page - 1);
      this.$emit('changePage', this.offset);
    },
  },
};
</script>
