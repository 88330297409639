<template>
  <table class="s4-pair-preview-table">
    <thead>
        <tr> 
          <th> 專題編碼 </th> 
          <th> 教授姓名 </th> 
          <th> 專題學生 </th>
        </tr>
      </thead>
    <tbody>
      <tr v-for="element in allProject" :key="element.id">
        <td class="s4-pair-preview-table-column">{{ element.pid }} - {{ element.project_name}}</td>
        <td class="s4-pair-preview-table-column">{{ element.teacher_name }}</td>
        <td class="s4-pair-preview-table-name-column">
          <span v-for="(student, idx) in element.members" :key="idx">
            <v-chip
              v-if="student.student_uid"
              class="ma-2"
              color="blue"
              label
              text-color="white"
            >
              <v-icon left>
                mdi-account-circle-outline
              </v-icon>
              {{ student.student_uid }} {{ student.student_name }}
            </v-chip>
          </span>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'adminpairPreviewTable',
  components: {
  },
  computed: mapGetters(['currentName', 'currentProjectYear']),
  mounted(){
    this.getAllStudent(this.currentProjectYear)
  },
  data(){
    return{
      professorName: "",
      resultTitle: "最終結果",
      allProject:[],
    }
  },
  methods:{
    async getAllStudent(year){
      var studentRecord = await this.$services.projectManage.getAllProjectRecord(year, 0, 999);
      var teachers = await this.$services.projectManage.getAllProjectTeacher(year);
      this.allProject = teachers.data.map(ele => ({
        "pid": ele.pid,
        "teacher_name": ele.teacher_name,
        "teacher_uid": ele.teacher_uid,
        "members": [],
        "project_name": ele.title
      }))
      studentRecord.data.forEach((ele) => {
        var idx = this.allProject.findIndex(e => e.pid == ele.pid);
        if(idx != -1){
          this.allProject[idx].members.push({
            "student_uid": ele.student_uid,
            "student_name": ele.student_name
          })
        }
      })
    },
    
  }
}
</script>
<style lang="scss" scoped>
table {
  width: 100%;
  border: 3px solid var(--v-blue-base);
  color: var(--v-darkGrey-base);
  background-color: #fff;
  font-weight: bold;
}

th {
  background-color: var(--v-blue-base);
  color: var(--v-white-base);
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -user-select: none;
}
tr{
  width: 100%;
  background-color: var(--v-lightGrey2-base);
}
th, td {
  padding: 10px 20px;
}
.s4-pair-preview-table{
  width: 100%; 
  max-width: 100%; 
  max-height: 150px; 
  overflow-x:hidden;
  overflow-y:auto;
}
.s4-pair-preview-table-column{
  width: 200px;
}
</style>
