<template>
  <div id="administration">
    <v-card width="100%" class="main-card" shaped>
      <v-tabs v-model="tab" background-color="transparent" color="blue" grow>
        <v-tab v-for="item in items" :key="item">
          {{ item }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card width="100%" flat>
            <form class="card-container add-announcement" id="user-form">
              <div class="form-item-container">
                <div class="form-label">上傳學生資料檔案（限 CSV 檔）</div>
                <label for="file-upload" class="file-upload-btn" v-if="!this.file">
                  <v-icon x-large>mdi-plus</v-icon>
                  <span>請選擇檔案</span>
                </label>
                <div v-else class="file-name-btn">
                  <v-icon x-large>mdi-file-check</v-icon>
                  <span>{{ this.file.name }}</span>
                </div>
                <input id="file-upload" type="file" ref="doc" @change="readFile" />
                <v-data-table v-if="content" :headers="headers" :items="content" :items-per-page="15"
                  class="preview-table"></v-data-table>
              </div>
            </form>
            <div class="btn-container">
              <v-btn depressed class="btn-clear" @click="deleteFile">
                取消
              </v-btn>
              <v-btn color="primary" @click="uploadStudentData">
                上傳
              </v-btn>
            </div>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card width="100%" class="new-project" flat>
            <div class="item-label">專題學年</div>
            <input class="text-input" v-model="projectConfig.year" placeholder="請輸入要設定的年份" />

            <!-- <hr class="divider" /> -->

            <div class="item">
              <div class="item-label">時程設定</div>

              <div class="item-container">
                <div class="sub-item-container">
                  <div class="sub-item-label">第一階段開始時間</div>
                  <DatePicker color="blue" @saveDate="saveStage1OpenDate" :datePicked="projectConfig.tage_1_open_date" />
                </div>
                <div class="sub-item-container">
                  <div class="sub-item-label">第一階段結束時間</div>
                  <DatePicker color="blue" @saveDate="saveStage1CloseDate"
                    :datePicked="projectConfig.tage_1_close_date" />
                </div>
              </div>

              <div class="item-container">
                <div class="sub-item-container">
                  <div class="sub-item-label">第二階段開始時間</div>
                  <DatePicker color="blue" @saveDate="saveStage2OpenDate" :datePicked="projectConfig.tage_2_open_date" />
                </div>
                <div class="sub-item-container">
                  <div class="sub-item-label">第二階段結束時間</div>
                  <DatePicker color="blue" @saveDate="saveStage2CloseDate"
                    :datePicked="projectConfig.tage_2_close_date" />
                </div>
              </div>

              <div class="item-container">
                <div class="sub-item-container">
                  <div class="sub-item-label">第三階段開始時間</div>
                  <DatePicker color="blue" @saveDate="saveStage3OpenDate" :datePicked="projectConfig.tage_3_open_date" />
                </div>
                <div class="sub-item-container">
                  <div class="sub-item-label">第三階段結束時間</div>
                  <DatePicker color="blue" @saveDate="saveStage3CloseDate"
                    :datePicked="projectConfig.tage_3_close_date" />
                </div>
              </div>

              <div class="item-container">
                <div class="sub-item-container">
                  <div class="sub-item-label">公佈分發結果時間</div>
                  <DatePicker color="blue" @saveDate="saveSeminarDate" :datePicked="projectConfig.seminar_date" />
                </div>
                <div class="sub-item-container">
                  <div class="sub-item-label">專題成果發表時間</div>
                  <DatePicker color="blue" @saveDate="saveAnnounceDate" :datePicked="projectConfig.announce_date" />
                </div>
              </div>
              <div class="project-btn-container">
                <v-btn color="primary" @click="createProjectConfig">
                  送出
                </v-btn>
              </div>
            </div>

            <hr class="divider" />

            <div class="item">
              <div class="item-label">可選修本學年專題的學生名單</div>
                <v-data-table :headers="projectStudentHeaders" :items="students" item-key="uid" class="py-2" style="width:100%" dense>
                  <template v-slot:[`item.action`]="{ item }">
                    <v-icon
                      size="small"
                      @click="removeItem('student', item)"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                </v-data-table>

              <v-text-field class="text-field the-text-field" v-model="studentKeyword" ref="search" full-width
                hide-details label="以學號或姓名搜尋其他可選修本學年專題的學生" color="blue" single-line append-icon="mdi-magnify"
                @click:append="searchUserByKeyword('student')"></v-text-field>
              <span v-if="studentNotFoundMessage && !studentSearchResult.length" class="error-message">{{
                studentNotFoundMessage }}</span>
              <v-data-table v-if="studentSearchResult?.length" :headers="projectStudentHeaders" :items="studentSearchResult" item-key="uid" class="py-2" style="width:100%" dense>
                <template v-slot:[`item.action`]="{ item }">
                  <v-icon
                    size="small"
                    @click="addToList('student', item)"
                  >
                    mdi-plus
                  </v-icon>
                </template>
              </v-data-table>
            </div>
            <div class="project-btn-container">
              <v-btn class="left-btn" color="primary" @click="createStudentRecord">
                創建
              </v-btn>
              <v-btn color="primary" @click="editStudentRecord">
                修改
              </v-btn>
            </div>

            <hr class="divider" />

            <div class="item">
              <div class="item-label">開授本學年專題課程的教授名單</div>
              <div class="item-description">
                若該名教授本學年不會開設專題課程，請點擊叉叉移除
              </div>
              <v-data-table v-if="professors.length" :headers="projectProfessorHeaders" :items="professors" item-key="uid" class="py-2" style="width:100%" dense>
                <template v-slot:[`item.action`]="{ item }">
                  <v-icon
                    size="small"
                    @click="removeItem('professor', item)"
                  >
                    mdi-delete
                  </v-icon>
                </template>
              </v-data-table>
              <div v-else class="empty-list">(請搜尋並選擇教授)</div>
              <v-text-field class="text-field" v-model="teacherKeyword" ref="search" full-width hide-details
                label="以姓名或教師編號搜尋會開設本年度課程的教授" single-line append-icon="mdi-magnify"
                @click:append="searchUserByKeyword('teacher')"></v-text-field>

              <span v-if="teacherNotFoundMessage && !teacherSearchResult.length" class="error-message">{{
                teacherNotFoundMessage }}</span>
              <v-data-table v-if="teacherSearchResult?.length" :headers="projectProfessorHeaders" :items="teacherSearchResult" item-key="uid" class="py-2" style="width:100%" dense>
                <template v-slot:[`item.action`]="{ item }">
                  <v-icon
                    size="small"
                    @click="addToList('professor', item)"
                  >
                    mdi-plus
                  </v-icon>
                </template>
              </v-data-table>
            </div>
            <div class="project-btn-container">
              <v-btn class="left-btn" color="primary" @click="createProfessorRecord">
                創建
              </v-btn>
              <v-btn color="primary" @click="editProfessorRecord">
                修改
              </v-btn>
            </div>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>

    <v-snackbar v-model="showSnackbar" :timeout="timeout" elevation="24" color="red">
      <v-icon>mdi-alert-octagram</v-icon> {{ errorMessage }}
    </v-snackbar>

    <v-snackbar v-model="showSuccessSnackbar" :timeout="timeout" elevation="24" color="green">
      <v-icon>mdi-check-circle</v-icon> {{ successMessage }}
    </v-snackbar>
  </div>
</template>
<script>
import * as csv from 'csv-parse/browser/esm';
import DatePicker from '@/components/DataPicker.vue';
import moment from 'moment';
import { mapGetters } from 'vuex';
export default {
  name: 'administration',
  components: {
    DatePicker,
  },
  data() {
    return {
      tab: '專題項目管理',
      items: ['學生資料管理', '專題設定管理'],
      headers: [
        { text: 'UID', value: 'uid', sortable: false },
        { text: 'status', value: 'status', sortable: false },
        { text: 'auth', value: 'auth', sortable: false },
        { text: 'grade', value: 'grade', sortable: false },
        { text: 'entry_time', value: 'entry_time', sortable: false },
        { text: 'leave_time', value: 'leave_time', sortable: false },
        { text: 'name', value: 'name', sortable: false },
        { text: 'nickname', value: 'nickname', sortable: false },
        { text: 'gender', value: 'gender', sortable: false },
        { text: 'mentor', value: 'mentor', sortable: false },
        { text: 'education', value: 'education', sortable: false },
        { text: 'job', value: 'job', sortable: false },
        { text: 'address', value: 'address', sortable: false },
        { text: 'phone', value: 'phone', sortable: false },
        { text: 'cellphone', value: 'cellphone', sortable: false },
        { text: 'email', value: 'email', sortable: false },
        { text: 'photo', value: 'photo', sortable: false },
        { text: 'website', value: 'website', sortable: false },
      ],
      selectedProjectStudent: [],
      projectStudentHeaders: [
        { text: 'UID', value: 'uid', sortable: true },
        { text: 'name', value: 'name', sortable: false },
        { text: 'status', value: 'status', sortable: false },
        { text: 'action', value: 'action', sortable: false },
      ],
      projectProfessorHeaders: [
        { text: 'UID', value: 'uid', sortable: true },
        { text: 'name', value: 'name', sortable: false },
        { text: 'action', value: 'action', sortable: false },
      ],
      file: null,
      content: null,
      studentSearchResult: [],
      teacherSearchResult: null,
      studentKeyword: '',
      teacherKeyword: '',
      studentIsNotFound: false,
      studentNotFoundMessage: '',
      teacherIsNotFound: false,
      teacherNotFoundMessage: '',
      students: [],
      newStudents: [],
      professors: [],
      newProfessors: [],
      timeout: 2000,
      showSnackbar: false,
      errorMessage: '',
      showSuccessSnackbar: false,
      successMessage: '',
      projectConfig: {
        year: new Date().getFullYear(),
        stage_1_open_date: '',
        stage_1_close_date: '',
        stage_2_open_date: '',
        stage_2_close_date: '',
        stage_3_open_date: '',
        stage_3_close_date: '',
        seminar_date: '',
        announce_date: '',
        course_select_min: 1,
        course_select_max: 10,
        student_select_max: 20,
      },
    };
  },
  computed: {
    ...mapGetters(['warningText']),
  },
  methods: {
    readFile() {
      this.file = this.$refs.doc.files[0];
      const reader = new FileReader();
      if (this.file.name.includes('.csv')) {
        reader.onload = () => {
          csv.parse(reader.result, (err, data) => {
            let jsonData = [];

            for (let i = 0; i < data.length; i++) {
              if (i === 0) {
                continue;
              }
              jsonData.push({
                uid: data[i][0],
                status: data[i][1],
                auth: data[i][2],
                grade: data[i][3],
                entry_time: data[i][4],
                leave_time: data[i][5],
                name: data[i][6],
                nickname: data[i][7],
                gender: data[i][8],
                mentor: data[i][9],
                education: data[i][10],
                job: data[i][11],
                address: data[i][12],
                phone: data[i][13],
                cellphone: data[i][14],
                email: data[i][15],
                photo: data[i][16],
                website: data[i][17],
              });
            }
            this.content = jsonData;
          });
        };
        reader.onerror = err => console.log(err);
        reader.readAsText(this.file);
      } else {
        this.content = 'Only csv file is acceptable';
      }
    },
    deleteFile() {
      this.content = null;
      this.file = null;
      document.getElementById('user-form').reset();
    },
    async uploadStudentData() {
      if (this.file === null || this.content === null) return;
      await this.$services.administration.createUser(this.content);
    },
    removeItem(type, target) {
      if (type === 'student') {
        this.students = this.students.filter(
          item => item.uid !== target.uid
        );
        this.newStudents = this.newStudents.filter(
          item => item.uid !== target.uid,
        );
      } else if (type === 'professor') {
        this.professors = this.professors.filter(
          item => item.uid !== target.uid,
        );
        this.newProfessors = this.newProfessors.filter(
          item => item.uid !== target.uid,
        );
      }
    },
    async getQualifiedStudents() {
      const students = await this.$services.administration.getQualifiedStudents();
      this.students = students;
    },
    async getAllTeachers() {
      const teachers = await this.$services.administration.getAllTeachers();
      this.professors = teachers.data;
    },
    async searchUserByKeyword(type) {
      this.studentIsNotFound = '';
      this.studentNotFoundMessage = '';
      this.studentSearchResult = [];
      this.teacherSearchResult = [];
      if (!this.studentKeyword && !this.teacherKeyword) return;
      const user = await this.$services.administration.searchUserByKeyword(
        type === 'student' ? this.studentKeyword : this.teacherKeyword,
        type,
      );
      if (!user.data.length && type === 'student') {
        this.studentIsNotFound = true;
        this.studentNotFoundMessage = '找不到該學生';
      } else if (!user.data.length && type === 'teacher') {
        this.teacherIsNotFound = true;
        this.teacherNotFoundMessage = '找不到該教授';
      }
      if (type === 'student') {
        this.studentSearchResult = user.data;
      } else {
        this.teacherSearchResult = user.data;
      }
    },
    addToList(type, item) {
      this.teacherKeyword = '';
      if (type === 'student') {
        if (this.students) {
          const result = this.students.filter(
            student => student.uid === item.uid,
          );
          if (result.length > 0) {
            this.showSnackbar = true;
            this.errorMessage = '重複了!!!';
            return;
          }
        }
        this.students.push(item);
        this.newStudents.push(item);
        // remove item from studentSearchResult, match by uid
        this.studentSearchResult = this.studentSearchResult.filter(
          student => student.uid !== item.uid,
        );
        this.studentKeyword = '';
      } else if (type === 'professor') {
        if (this.professors.length > 0) {
          const result = this.professors.filter(
            professor => professor.uid === item.uid,
          );
          if (result.length > 0) {
            this.showSnackbar = true;
            this.errorMessage = '重複了!!!';
            return;
          }
        }
        this.professors.push(item);
        this.newProfessors.push(item);
        this.teacherSearchResult = this.teacherSearchResult.filter(
          professor => professor.uid !== item.uid,
        );
        this.teacherKeyword = '';
      }
    },
    saveStage1OpenDate(date) {
      this.projectConfig.stage_1_open_date =
        moment(date).format('YYYY-MM-DDT23:59:59') + 'Z';
    },
    saveStage1CloseDate(date) {
      this.projectConfig.stage_1_close_date =
        moment(date).format('YYYY-MM-DDT23:59:59') + 'Z';
    },
    saveStage2OpenDate(date) {
      this.projectConfig.stage_2_open_date =
        moment(date).format('YYYY-MM-DDT23:59:59') + 'Z';
    },
    saveStage2CloseDate(date) {
      this.projectConfig.stage_2_close_date =
        moment(date).format('YYYY-MM-DDT23:59:59') + 'Z';
    },
    saveStage3OpenDate(date) {
      this.projectConfig.stage_3_open_date =
        moment(date).format('YYYY-MM-DDT23:59:59') + 'Z';
    },
    saveStage3CloseDate(date) {
      this.projectConfig.stage_3_close_date =
        moment(date).format('YYYY-MM-DDT23:59:59') + 'Z';
    },
    saveSeminarDate(date) {
      this.projectConfig.seminar_date =
        moment(date).format('YYYY-MM-DDT23:59:59') + 'Z';
    },
    saveAnnounceDate(date) {
      this.projectConfig.announce_date =
        moment(date).format('YYYY-MM-DDT23:59:59') + 'Z';
    },
    async createProjectConfig() {
      this.projectConfig.year = parseInt(this.projectConfig.year);
      const {
        data: res,
      } = await this.$services.administration.createProjectConfig(
        this.projectConfig,
      );

      if (res.year) {
        this.showSuccessSnackbar = true;
        this.successMessage = `${res.year}專題年度創建成功`;
      }
    },
    async getCurrentProjectRecords() {
      const records = await this.$services.administration.getCurrentProjectRecords(
        this.projectConfig.year,
      );
      return records;
    },
    findDifference(originalList, newList) {
      let result = [];
      const originalUids = originalList.map(user => user.uid);
      const newUids = newList.map(user => user.uid);
      newUids.forEach(uid => {
        if (originalUids.indexOf(uid) === -1) {
          result.push(uid);
        }
      });
      return result;
    },
    async createStudentRecord() {
      const studentIdList = this.students.map(student => student.uid);
      const {
        data: res,
      } = await this.$services.administration.createStudentRecord(
        studentIdList,
      );
      if (res && !this.warningText) {
        localStorage.setItem('studentCreated', true);
      }
    },
    async editStudentRecord() {
      // 要用來比對才是要新增的、誰是要刪除的，要等後端的新的 api
      // const o = [{ uid: '1' }, { uid: '2' }, { uid: '3' }];
      // const n = [{ uid: '2' }, { uid: '3' }, { uid: '4' }, { uid: '5' }];
      // const studentIdList = this.students.map(student => student.uid);
      // const newStudentIdList = this.newStudents.map(student => student.uid);
      this.getQualifiedStudents();
      const deleteList = this.findDifference(this.newStudents, this.students);

      const addList = this.findDifference(this.students, this.newStudents);
      //
      // 要用來拿原始的 PROJECT RECORDS 的，然後再拿去比對，最後打後端新增的 API
      // get projects records
      // const result = await this.getCurrentProjectRecords();

      await this.$services.administration.editStudentRecord({
        add_uid_list: addList,
        remove_uid_list: deleteList,
      });

      // const studentIdList = this.students.map(student => student.uid);
      //   const {
      //     data: res,
      //   } = await this.$services.administration.createStudentRecord(
      //     studentIdList,
      //   );
      //   if (res.length) {
      //     this.showSuccessSnackbar = true;
      //     this.successMessage = '專題學生名單創建成功';
      //   }
      //
      // ------
      // try {
      //   const studentIdList = this.students.map(student => student.uid);
      //   const {
      //     data: res,
      //   } = await this.$services.administration.createStudentRecord(
      //     studentIdList,
      //   );
      //   if (res.length) {
      //     this.showSuccessSnackbar = true;
      //     this.successMessage = '專題學生名單創建成功';
      //   }
      // } catch (err) {
      //   this.showSnackbar = true;
      //   this.errorMessage = '部分專題學生名單已存在，請重新確認';
      // }
    },
    async createProfessorRecord() {
      const projectList = this.professors.map(professors => professors.uid);
      await this.$services.administration.createProjectsByProfessors(
        projectList,
      );
    },
    async editProfessorRecord() {
      // try {
      //   const professorIdList = this.professors.map(professor => professor.uid);
      //   const {
      //     data: res,
      //   } = await this.$services.administration.createProjectsByProfessors(
      //     professorIdList,
      //   );
      //   if (res.length) {
      //     this.showSuccessSnackbar = true;
      //     this.successMessage = '教授名單創建成功';
      //   }
      // } catch (err) {
      //   this.showSnackbar = true;
      //   this.errorMessage = '部分專題教授名單已存在，請重新確認';
      // }
      this.getAllTeachers();
      const deleteList = this.findDifference(
        this.newProfessors,
        this.professors,
      );
      const addList = this.findDifference(this.professors, this.newProfessors);
      //
      // 要用來拿原始的 PROJECT RECORDS 的，然後再拿去比對，最後打後端新增的 API
      // get projects records
      // const result = await this.getCurrentProjectRecords();

      await this.$services.administration.editProfessors({
        add_uid_list: addList,
        remove_uid_list: deleteList,
      });
    },
    // clearList(type) {
    //   if (type === 'student') {
    //     this.students = [];
    //   } else if (type === 'teacher') {
    //     this.professors = [];
    //   }
    // },
  },
  mounted() {
    this.getQualifiedStudents();
    this.getAllTeachers();
  },
};
</script>
<style lang="scss" scoped>
#administration {
  padding: 33px 16% 33px 8%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-weight: bold;

  height: 100%;

  form {
    width: 100%;
    background-color: var(--v-white-base);
    width: 100%;
    height: 100%;
    border-radius: 10px;
    padding: 50px 100px;
    color: var(--v-darkGrey-base);

    .form-item-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 20px;

      .preview-table {
        margin-top: 20px;
      }

      .form-label {
        margin-bottom: 10px;
      }

      .form-item {
        width: 200px;
      }

      .text-input {
        width: 100%;
        border: 2px solid var(--v-lightGrey-base);
        border-radius: 5px;
        padding: 5px;
        color: var(--v-black-base);
        outline: none;
      }

      .text-input:focus {
        border: 2px solid var(--v-blue-base);
        color: var(--v-blue-base);
      }

      input[type='file'] {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        border: 0;
      }

      .file-upload-btn,
      .file-name-btn {
        width: 150px;
        height: 150px;
        border: 1px solid var(--v-lightGrey-base);
        border-radius: 5px;
        padding: 5px;
        color: var(--v-lightGrey-base);
        background-color: var(--v-lightGrey2-base);
        outline: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        span {
          margin-top: 10px;
        }
      }

      .file-name-btn {
        background-color: var(--v-white-base);
      }
    }

    .select-style {
      margin-bottom: 0;
    }
  }

  .btn-container {
    width: 100%;
    padding: 50px 100px;
    display: flex;
    justify-content: flex-end;

    .btn-clear {
      margin-right: 10px;
    }
  }

  .project-btn-container {
    width: 100%;
    padding: 50px 0 20px 0;
    display: flex;
    justify-content: flex-end;

    .btn-clear {
      margin-right: 10px;
    }
  }

  .new-project {
    width: 100%;
    padding: 50px 100px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    color: var(--v-darkGrey-base);

    .divider {
      margin-top: 40px;
      border: 0.5px dashed rgb(228, 228, 228);
      width: 100%;
    }

    .item {
      width: 100%;
      margin-top: 40px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      .item-description {
        font-size: 14px;
        margin-top: -10px;
        margin-bottom: 20px;
        color: var(--v-lightGrey-base);
      }

      .chips {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        flex-wrap: wrap;
      }

      .empty-list {
        font-size: 16px;
        color: var(--v-lightGrey-base);
        margin-left: 10px;
      }

      .text-field {
        width: 90%;
        margin-left: 10px;
      }

      ::v-deep .v-label {
        color: var(--v-lightGrey-base);
      }

      .error-message {
        font-size: 12px;
        margin-left: 10px;
        margin-top: 5px;
        color: var(--v-orange-base);
      }
    }

    .left-btn {
      margin-right: 5px;
    }

    .item-label {
      margin-bottom: 10px;
    }

    .sub-item-label {
      margin-top: 10px;
      font-size: 14px;
    }

    .item-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 20px;

      .sub-item-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-right: 50px;
      }
    }

    .text-input {
      width: 200px;
      border: 2px solid var(--v-lightGrey-base);
      border-radius: 5px;
      padding: 5px;
      color: var(--v-black-base);
      outline: none;
    }

    .text-input:focus {
      border: 2px solid var(--v-blue-base);
      color: var(--v-blue-base);
    }
  }

  .chips {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}
</style>
