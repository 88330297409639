<template>
<div>
    <v-dialog
      v-model="isOpen"
      max-width="900"
    >
      <div class="project-main">
        <div class="project-edit-title">新增 {{ this.currentProjectYear -1911}} 學年度專題內容 </div>

        <div class="project-item project-item-small">
          <div class="project-title">教授名稱</div>
          <div class="projec-content">
            <v-overflow-btn v-show="this.isAdmin" class="ma-0 pa-0" flat :items="professorList" 
            item-text="name" item-value="uid" counter v-model="project.teacher_uid">
            </v-overflow-btn>
            
            <v-textarea disabled v-show="this.isTeacher" v-model="currentName" class="ma-0 pa-0" @keydown.enter.prevent="noNextLine"
              rows="1" no-resize solo flat row-height="5">
            </v-textarea>
          </div>
        </div>

        <div class="project-item project-item-small">
          <div class="project-title">專題名稱</div>
          <div class="projec-content">
            <v-textarea v-model="project.title" class="ma-0 pa-0" @keydown.enter.prevent="noNextLine"
              rows="1" no-resize solo flat row-height="5"
              placeholder="填入專題名稱">
            </v-textarea>
          </div>
        </div>

        <div class="project-item project-item-big">
          <div class="project-title">專題內容</div>
          <div class="projec-content">
            <v-textarea v-model="project.description" class="ma-0 pa-0"
              no-resize solo flat row-height="5"
              placeholder="填入專題內容">
            </v-textarea>
          </div>
        </div>
        <v-img :src="require('../../assets/purple-save.svg')" 
        contain class="project-save" @click="closeDialog()">
        </v-img>
      </div>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  props: { isDialogOpen: { type: Boolean, default: true } },
	name: 'ProjecEditDialog',
  async mounted(){
    if(this.isTeacher){
      this.project.teacher_uid = this.currentUID;
    }
    else if(this.isAdmin){
      this.professorList = (await this.$services.projectManage.getAllProjectTeacher(this.currentProjectYear)).data;
    }
  },
	data() {
		return {
      professorList:[],
      isOpen: false,
      project:{
        year: this.currentProjectYear,
        teacher_uid: "",
        title: "",
        description: "",
        markdown: "empty"
      }
		};
	},
  computed: {
    ...mapGetters(['isAdmin', 'isStudent', 'isTeacher', 'currentName', 'currentUID', 'currentProjectYear']),
  },
  watch:{
    isDialogOpen(){
      this.isOpen = this.isDialogOpen;
    },
  },
  methods:{
    noNextLine(){},
    closeDialog(){
      this.createProjectOnStageOne(this.project);
      this.$emit("closeProjectDialog");
    },
    async createProjectOnStageOne(project){
      project.year = parseInt(this.currentProjectYear)
      const data = await this.$services.projectManage.createProjectOnStageOne(project);
      this.addProject(data.data);
    },
    ...mapActions(['addProject'])
  }
};
</script>
<style scoped>
.project-save{
  height: 50px;
  display: flex;
  align-self: flex-end;
}
.project-save:hover{
  cursor: pointer;
}
.v-textarea.v-text-field--enclosed textarea {   margin-top: 0px !important; }
.project-edit-title{
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 2.56px;
  text-align: left;
  color: #364e6b;
  margin-bottom: 30px;
}
.v-dialog__container {
  display: unset; 
}
.project-main{
  padding: 30px 30px 20px 30px;
  box-shadow: 0 3px 23px 0 rgba(0, 0, 0, 0.4);
  background-color: #ffffff;
  height: 600px;
  width: 900px;
  justify-content: space-around;
  display: flex;
  flex-direction: column;
}
.project-item{
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
}
.project-item-small{
  height: 50px;
}
.project-item-big{
  height: 300px;
}
.project-title{
  background-color: #9ed3fd;
  width: 100px;
  height: 100%;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1.02px;
  text-align: center;
  color: #ffffff;
  padding: 10px;
  border-radius: 12px 0px 0px 12px;
  background-color: #9ed3fd;
  justify-content: center;
}
.projec-content{
  height: 100%;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: #6d6d6d;
  flex-wrap:wrap;
  word-wrap:normal;
  width: 80%;
  justify-content: center;
  vertical-align: middle;
}
</style>