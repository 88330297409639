<template>
	<div>
		<table class="s3-order-table">
			<thead>
				<tr> <th> {{ selectedTitle }} </th> </tr>
			</thead>
			<tbody>
				<draggable v-model="selected" group="people" @start="drag=true" @end="drag=false">
					<tr v-for="element in selected" :key="element.student_uid">
						<v-icon>mdi-drag</v-icon>
						<td >{{ element.student_uid }}</td>
						<td class="s3-order-table-name-column">
							{{ element.student_name }}
						</td>
						<v-icon @click.native="removeStudent(element.student_uid)" color="var(--v-red-base)">mdi-close-circle</v-icon>
					</tr>
				</draggable>
			</tbody>
		</table>

		<div class="s3-save-btn-contanier">
			<v-btn :disabled="!isSelectedChange"
			v-on:click="createPriority(project.pid)" class="s3-save-btn" color=var(--v-purple-base) elevation="2" height="50px">
				{{ buttonTitle }}
			</v-btn>
		</div>
		
		<table class="s3-priority-table">
			<thead>
				<div class="s3-priority-table-contanier">
					<input v-model="searchText" placeholder="學生學號或姓名"/>
				</div>
				<tr>
					<th>排序 | 學號 | 姓名</th>
				</tr>
			</thead>
			<tbody>
				<draggable v-model="studentList" group="people" @start="drag=true" @end="drag=false"> 
					<tr v-for="element, id in studentList" :key="id" @click="click(id)">
						<td v-if="element.priority!=-1" class="s3-td-200"> {{element.priority}}</td>
						<td v-else class="s3-td-200"></td>
						<td class="s3-td-200"> {{element.student_uid}}</td>
						<td class="s3-td-full"> {{element.student_name}}</td>
					</tr>
				</draggable>
			</tbody>
		</table>

		<div class="s3-pagination-container">
      <Pagination :currentTotalPage="this.totalPage" :limit="this.limit" @changePage="(offset) => this.changePage(offset)"/>
		</div>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import draggable from 'vuedraggable'
import Pagination from './Pagination.vue'
export default {
  props: { 
		project: { type: Object, default: function(){return {}} }
	},
	name: 'studentOrderTable',
	components: {
		draggable,
    Pagination
	},
	async mounted(){
		// get selected teacher priority
		await this.getTeacherPriority();
    // get students of top 10 priority
		await this.getNthPriority();
    this.studentList = [...this.studentList].sort(this.sortByPriority)
    this.isSelectedChange = false
	},
	data(){
		return{
			bufferSearch:[],
			searchText:"",
			buttonTitle: "繳交選擇",
			selectedTitle: "您的專題生",
			selected:[],
			studentList:[],
			isSelectedChange:false,
      totalPage: 1,
      limit: 10,
      offset: 0,
      priorities: []
		}
	},
	computed: {
    ...mapGetters(['isAdmin', 'isTeacher',  'currentName', 'currentUID', 'currentProjectYear'])
  },
  methods:{
    changePage(offset){
      this.offset = offset
      this.getTeacherPriority();
      // get students of top 10 priority
      this.studentList = []
			this.getNthPriority();
      this.studentList = [...this.studentList].sort(this.sortByPriority)
      this.isSelectedChange = false
    },
    click(idx){
      this.selected.push(this.studentList[idx]);
      this.studentList.splice(idx, 1);
      this.isSelectedChange = true;
    },
    sortByPriority(a, b){
      let key = "priority"
      if(b[key] == -1) return 1
      if(a[key] == -1) return 1
      if(b[key] < a[key]) return 1
      else return -1
    },
		removeStudent(objectId){
			for (var i = 0; i < this.selected.length; i++) {
				if (this.selected[i].student_uid === objectId) {
					this.studentList.push(this.selected[i])
          this.studentList = [...this.studentList].sort(this.sortByPriority)
					this.selected.splice(i, 1);
					this.isSelectedChange = true
				}
			}
      
		},
		createPriority(pid){
      let request = {
        "year": this.currentProjectYear.toString(),
        "pid": pid,
        "priorities": []
      }
			var cnt = 0
			this.selected.forEach((ele) => {
				cnt += 1
				request.priorities.push({
					"student_uid": ele.student_uid,
					"priority": cnt
				})
			})
			this.$services.projectManage.createTeacherPriority(request)
			this.isSelectedChange = false
		},
    async getNthPriority(){
      var priority = 10
      var topStudent = await this.$services.projectManage.getNthPriority(this.currentProjectYear, this.project.teacher_uid, priority, 0, 999)
      this.totalPage = topStudent.total_page
      topStudent.data.forEach((stu) => {
        if(!this.priorities.find(e => e.student_uid == stu.student_uid)){
          this.studentList.push({
            "student_uid": stu.student_uid,
            "priority": stu.priority,
            "student_name": stu.student_name,
          })
        }
      })
      // TODO : make up for the selected student

      this.studentList = [...this.studentList].sort(this.sortByPriority)
    },
    async getTeacherPriority(){
      if(this.isTeacher){
        this.priorities = await this.$services.projectManage.getSelectedTeacherPriority(this.currentProjectYear)
        this.selected = []
        this.priorities.forEach(pri => {
          if(pri.pid == this.project.pid){
            this.selected.push(pri)
          }
        })
      }
      else if(this.isAdmin){
        this.priorities = []
        let priorities = await this.$services.projectManage.adminGetSelectedTeacherPriority(this.currentProjectYear, this.project.pid)
        this.selected = priorities.data
        if(priorities.data.length > 0){
          let teacher_uid = priorities.data[0].teacher_uid
          this.priorities = (await this.$services.projectManage.adminGetAllSelectedTeacherPriority(this.currentProjectYear, teacher_uid)).data;
        }
      }
    }
	},
	watch: {
		searchText(){
			let all = this.studentList.concat(this.bufferSearch)
			this.studentList = []
			this.bufferSearch = []
			all.forEach(ele => {
				if(ele.student_name.toLowerCase().includes(this.searchText.toLowerCase()) || 
				ele.student_uid.toLowerCase().includes(this.searchText.toLowerCase())){
					this.studentList.push(ele)
				}
				else{
					this.bufferSearch.push(ele)
				}
			})
		},
		selected(value, newValue){
			if(value != newValue){
				this.isSelectedChange = true
			}
    }
	},
}
</script>
<style lang="scss" scoped>
table {
	width: 100%;
	border: 3px solid var(--v-blue-base);
	color: var(--v-darkGrey-base);
	background-color: #fff;
	font-weight: bold;
}

th {
	background-color: var(--v-blue-base);
	color: var(--v-white-base);
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-user-select: none;
}
tr{
	width: 100%;
	background-color: var(--v-lightGrey2-base);;
	cursor: pointer;
}

th, td {
	min-width:150px;
	padding: 10px 20px;
}
.tab-group {
	display: flex;
	flex-direction: row;
	justify-items: flex-start;
	margin-top: 35px;
}
.tab,
.tab:hover{
	width: 100px;
	height: 40px;
	line-height: 45px;
	font-weight: bold;
	margin: 0 3px 2px 0;
	border-radius: 10px 20px 0 0;
	cursor: pointer;
}
.tab:hover,
.tab.isSelected {
	background-color: var(--v-blue-base);
}
.tab-order {
	color: var(--v-white-base);
	background-color: var(--v-lightGrey-base);
}
.s3-priority-table{
	width: 100%; 
	margin-bottom: 30px;
}
.s3-td-200{
	width: 200px;
}
.s3-td-full{
	width:100%;
}
.s3-priority-table-contanier{
	height: 40px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	width: 100%;
	padding: 3px;
	border: solid 2px transparent ;
	border: 2px 2px 1px 2px;
	border-bottom-color: var(--v-blue-base);
	background-color: var(--v-white-base);
	margin-bottom: 2px;
	input{
		color: var(--v-lightGrey-base);
		width: 700px;
		padding: 5px;
		outline: none;
	}
}
::placeholder {
	color: var(--v-lightGrey-base);;
	opacity: 1; /* Firefox */
}
.s3-order-table{
	width: 100%; 
	max-width: 100%; 
	max-height: 150px; 
	overflow-x:hidden;
  overflow-y:auto;
}
.s3-order-table-name-column{
	width: 100%;
}
.s3-pagination-container{
	margin-top: 10px;
	display: flex;
	flex-direction: column-reverse;
	align-items: center;
}
.s3-save-btn-contanier{
	margin-top: 30px;
	margin-bottom: 30px;
	display: flex;
	flex-direction: row-reverse;
	align-items: flex-end;
}
.s3-save-btn{
	width: 150px;
	color: var(--v-white-base);
	font-size: 20px;
	font-weight: bold;
	border-radius: 15px;
	height: 50px;
	background-color: var(--v-purple-base);
}
</style>