<template>
  <table>
    <thead>
      <tr>
        <th colspan="5" class="top-left-corner top-right-corner">
          <div class="table-title">
            <span>資格考研究領域口試</span>
            <v-icon
              v-if="!isEditMode && (isAdmin)"
              small
              color="#FFFFFF"
              class="edit-btn"
              @click="changeMode"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              v-else-if="isAdmin"
              small
              color="#FFFFFF"
              class="save-btn"
              @click="saveEdit"
            >
              mdi-check-bold
            </v-icon>
          </div>
        </th>
      </tr>
      <tr>
        <th>期限</th>
        <th>狀態</th>
        <th class="paper-note">論文題目</th>
        <th>日期</th>
        <th>系辦審核</th>
      </tr>
    </thead>
    <tbody v-if="!isEditMode">
      <tr v-for="item in academicData.oral_exams" :key="item.note">
        <td>{{ item.deadline }}</td>
        <td>{{ researchResultOptions[item.is_passed] }}</td>
        <td>
          {{ item.note ? item.note : '/' }}
        </td>
        <td>
          {{ item.date }}
        </td>
        <td>{{ overallResultOptions[item.confirm] }}</td>
      </tr>
      <tr>
        <td colspan="5" class="bottom-left-corner bottom-right-corner note">
          {{ academicData.oral_exam_note ? academicData.oral_exam_note : '/' }}
        </td>
      </tr>
    </tbody>
    <tbody v-else>
      <tr v-for="(item, idx) in academicData.oral_exams" :key="item.note">
        <td>
          <DatePicker
            color="blue"
            @saveDate="saveDeadlineDate"
            :datePicked="item.deadline"
            :idx="idx"
          />
        </td>
        <td class="radio-cell">
          <v-radio-group v-model="item.is_passed">
            <v-radio
              v-for="n in researchResultOptions.length"
              :key="n"
              :label="researchResultOptions[n - 1]"
              :value="n - 1"
              actice-class="radio-style"
              color="blue"
            ></v-radio>
          </v-radio-group>
        </td>
        <td>
          <div class="note-textarea">
            <textarea placeholder="請輸入論文題目" v-model="item.note" />
          </div>
        </td>
        <td>
          <DatePicker
            color="blue"
            @saveDate="saveDate"
            :datePicked="item.date"
            :idx="idx"
          />
        </td>
        <td class="radio-cell">
          <v-radio-group v-model="item.confirm">
            <v-radio
              v-for="n in overallResultOptions.length"
              :key="n"
              :label="overallResultOptions[n - 1]"
              :value="n - 1"
              actice-class="radio-style"
              color="blue"
            ></v-radio>
          </v-radio-group>
        </td>
      </tr>

      <tr v-if="academicData.oral_exams && academicData.oral_exams.length < 2">
        <td colspan="5">
          <v-icon small color="#FFFFFF" class="plus-btn" @click="addOralExam">
            mdi-plus
          </v-icon>
        </td>
      </tr>

      <tr>
        <td colspan="5" class="bottom-left-corner bottom-right-corner note">
          <div class="note-textarea">
            <textarea
              placeholder="請輸入備註"
              v-model="academicData.oral_exam_note"
            />
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import DatePicker from '@/components/DataPicker.vue';
import { mapGetters } from 'vuex';
import moment from 'moment';
const researchResultOptions = ['不通過', '通過'];
export default {
  props: {
    tableTitle: { type: String, default: '' },
  },
  components: {
    DatePicker,
  },
  data() {
    return {
      isEditMode: false,

      researchResultOptions: researchResultOptions,
      overallResultOptions: ['警告', '通過'],
    };
  },
  computed: {
    ...mapGetters(['academicData', 'currentUID', 'isAdmin', 'warningText']),
  },
  methods: {
    changeMode() {
      this.isEditMode = !this.isEditMode;
    },
    saveDeadlineDate(date, idx) {
      this.academicData.oral_exams[idx].deadline = date;
    },
    saveDate(date, idx) {
      this.academicData.oral_exams[idx].date = date;
    },
    addOralExam() {
      this.academicData.oral_exams.push({
        is_passed: undefined,
        deadline: moment().format('YYYY-MM-DD'),
        date: moment().format('YYYY-MM-DD'),
        confirm: undefined,
        note: '',
      });
    },
    async saveEdit() {
      this.academicData.oral_exams = this.academicData.oral_exams.filter(
        exam => exam.note,
      );
      const oralExamList = this.academicData.oral_exams.map(item => {
        return {
          is_passed:
            item.is_passed == undefined
              ? undefined
              : item.confirm === 1
              ? true
              : false,
          note: item.note,
          date: moment(item.date).format('YYYY-MM-DDT23:59:59') + 'Z',
          deadline: moment(item.deadline).format('YYYY-MM-DDT23:59:59') + 'Z',
          confirm:
            item.confirm == undefined
              ? undefined
              : item.confirm === 1
              ? true
              : false,
        };
      });
      const oralExam = {
        oral_exams: oralExamList,
        oral_exam_note: this.academicData.oral_exam_note,
      };

      await this.$services.academic.saveOralExam(
        this.academicData.uid,
        oralExam,
      );
      if (!this.warningText) {
        this.isEditMode = !this.isEditMode;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/scss/academy.scss';
</style>
