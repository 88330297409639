<template>
  <div class="gs-card" @click="routeProfile">
    <img :src="this.photo" class="gs-card-img" :onerror="`this.src='${this.defaultProfile}'`" />
    <div class="gs-card-info">
      <div class="gs-card-name">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <span
              v-bind="attrs"
              v-on="on"
              class="gs-card-status"
              :style="`background: ${statusMap(people.status).color}`"
            ></span>
          </template>
          <span>{{statusMap(people.status).text}}</span>
        </v-tooltip>
        {{ people.name}}
      </div>
      <div class="gs-card-id"> {{ people.uid}} </div>
    </div>
  </div>
</template>

<script>
import config from '../../config';
export default {
  props: { people: { type: Object, default: function () { return {} } } },
  data() {
    return {
      defaultProfile: require("../assets/defaultProfile.jpg")
    };
  },
  computed: {
    photo() {
      return `${config.BASEURL}/image/${this.people.photo}`;
    }
  },
  methods:{
    routeProfile(){
      this.$router.push(`/Profile/${this.people.uid}`)
    },
    statusMap(status){
      const mapping = {
        "present"  : {text: '在學', color: 'LightGreen'},
        "double"   : {text: '雙主修', color: 'LightSeaGreen'},
        "transfer" : {text: '轉系', color: 'LightSalmon'},
        "suspend"  : {text: '休學', color: 'LightGray'},
        "dropout"  : {text: '離校', color: 'LightCoral'},
        "alumni"   : {text: '畢業', color: 'LightSkyBlue'}, 
        "leave"    : {text: '離職', color: 'LightSteelBlue'}
      } 
      return mapping[status];
    }
  }
};
</script>

<style scoped lang="scss">
.gs-card{
  cursor: pointer;
  height: 260px;
  overflow: hidden;
  border-radius:  8px;
  background-color: var(--v-white-base);
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
  &:hover{
    box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
  }
  .gs-card-img{
    width:  100%;
    height: 75%;
    border-bottom: LightBlue 3px solid;
    object-fit: cover;
  }
  .gs-card-info{
    :first-child{
      font-size: 16px;
      font-weight: 600;
    }
    :nth-child(2){
      font-size: 16px;
      color: Gray;
    }
  }
  .gs-card-status{
    display: inline-block;
    height: 8px;
    width: 8px;
    border-radius: 100%;
    margin-right: 4px;
    margin-bottom: 1px;
  }
}
</style>