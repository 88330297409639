export default class GlobalSearchService {
  constructor(api) {
    this.api = api;
  }

  getSearchResult(query, offset, limit) {
    var request = query
    if(limit > 0) {
      request["offset"] = offset
      request["limit"] = limit
    }else{
      request['retrieve_all'] = true
    }
    return this.api.get(`/user/profile/public/search`, request);
  }
}
  