<template>
  <div id="global-search">
    <div>
      <div class="page-title">系友資訊查詢</div>
      <div class="gs-search-basic">
        <input v-model="keyword" class="gs-input" style="width:700px" placeholder="關鍵字 (姓名/學號/帳號/專題)" />
        <v-divider class="gs-divider" vertical></v-divider>
        <v-select :items="statusOption" item-text="text" item-value="key"
          solo flat class="gs-select" label="狀態" v-model="status"
        ></v-select>
        <v-divider  class="gs-divider" vertical></v-divider> 
        <v-select :items="gradeOption" item-text="text" item-value="key"
          solo flat class="gs-select" label="年級" v-model="grade"
        ></v-select>
        <v-btn :height="30" color="#ffbc6f" class="gs-search-btn" v-on:click="()=>{this.offset=0;this.totalPage=0;this.search()}"> 搜尋</v-btn>
      </div>
      <div class="gs-search-advanced">
        <v-select 
          solo flat dense
          class="gs-select"
          label="入學年度"
          hide-details
          v-model="entry_year"
          :items="yearOption"
        ></v-select>

        <v-select 
          solo flat dense
          class="gs-select"
          label="離校年度"
          hide-details
          v-model="leave_year"
          :items="yearOption"
        ></v-select>

        <v-select 
          solo flat dense
          class="gs-select"
          label="導師"
          hide-details
          v-model="mentor"
          :items="teacherOptions"
          item-text="text"
          item-value="key"
        ></v-select>

        <v-select
          class="gs-select"
          solo flat dense
          label="查詢上限"
          hide-details
          v-model="limit"
          :items="limitOption"
          item-text="text"
          item-value="key"
        ></v-select>

        <v-switch
          v-model="thumbnail"
          :label="thumbnail?'縮圖':'條列'"
          hide-details
          class="mt-1"
        ></v-switch>
          </div>
      <v-divider  class="gs-divider mb-2 mt-2" horizontal></v-divider> 
      <div v-if="thumbnail" class="gs-card-container mb-4" >
        <PersonalCard v-for="peo in people" :key="peo.id" :people="peo"/>
      </div>
      <div v-else>
        <template>
          <v-data-table
            :headers="headers"
            :items="people"
            class="elevation-3 gs-table py-2 mb-4"
            @click:row="goDetail"
            :loading="loading"
            disable-pagination
            hide-default-footer
            dense
          >
            <template v-slot:[`item.uid`]="{ item }">
                <span >{{ item.uid }}</span>
            </template>
            <template v-slot:[`item.grade`]="{ item }">
                <span>{{ gradeOption.filter(x=>x.key==item.grade)[0].text }}</span>
            </template>
            <template v-slot:[`item.status`]="{ item }">
                <span><span :style="`background:${statusMap[item.status].color}`" class="gs-status"></span>{{ statusMap[item.status].text }}</span>
            </template>
            <template v-slot:[`item.email`]="{ item }">
                <a :href="`mailto:${item.email}`">{{ item.email }}</a>
            </template>
            <template v-slot:[`item.entry_time`]="{ item }">
                <span>{{ item.entry_time.substr(0,4) }}</span>
            </template>
            <template v-slot:[`item.mentor_name`]="{ item }">
                <span :class="item.mentor_name?'black--text':'grey--text'">{{ item.mentor_name || '未設置' }}</span>
            </template>

          </v-data-table>
        </template>
      </div>
      <Pagination v-if="this.people.length != 0 && this.totalPage" :currentTotalPage="this.totalPage" :limit="this.limit" @changePage="(offset) => this.changePage(offset)"/>
    </div>
  </div>
  
</template>

<script>
import PersonalCard from '../components/PersonalCard.vue';
import Pagination from '../components/ProjectManage/Pagination.vue'
export default {
  name: 'global-search',
  components: {
    PersonalCard,
    Pagination
  },
  data(){
    return{
      //status
      loading: false,
      thumbnail: true,
      totalPage: 1,
      limit: 12,
      offset: 0,
      //query
      leave_year: null,
      entry_year: null,
      mentor: null,
      keyword: "",
      status: null,
      grade: null,
      //data
      headers: [
          { text: '狀態', value: 'status' },
          { text: '學號', value: 'uid' },
          { text: '姓名', value: 'name' },
          { text: '部門', value: 'grade' },
          { text: '入學年度', value: 'entry_time' },
          { text: '電子郵件', value: 'email' },
          { text: '導師', value: 'mentor_name' },
        ],
      statusMap: {
        "present"  : {text: '在學', color: 'LightGreen'},
        "double"   : {text: '雙主修', color: 'LightSeaGreen'},
        "transfer" : {text: '轉系', color: 'LightSalmon'},
        "suspend"  : {text: '休學', color: 'LightGray'},
        "dropout"  : {text: '離校', color: 'LightCoral'},
        "alumni"   : {text: '畢業', color: 'LightSkyBlue'}, 
        "leave"    : {text: '離職', color: 'LightSteelBlue'}
      },
      people:[],
      statusOption:
      [ { "key": null, "text": "狀態"},
        { "key": "present", "text":  '在學'},
        { "key" : "double"   ,"text" : '雙主修',},
        { "key" : "transfer" ,"text" : '轉系', },
        { "key" : "suspend"  ,"text" : '休學', },
        { "key" : "dropout"  ,"text" : '離校' },
        { "key" : "alumni"   ,"text" : '畢業' }, 
        { "key" : "leave"   ,"text" : '離職'}  
      ],
      gradeOption:[
        { "key": null, "text": "年級"},
        { "key": "bba", "text": '學士班'},
        { "key": "mba", "text": '碩士班'},
        { "key": "phd", "text": '博士班'},
        { "key": "teacher", "text": '教師'},
        { "key": "faculty" ,"text": '職員'}
      ],
      teacherOptions: [],
      yearOption: [],
      limitOption: [
        {key: 20, text: '20'},
        {key: 50, text: '50'},
        {key: 100, text: '100'},
        {key: 0, text: '不限'}
      ]
    }
  },
  methods:{
    changePage(offset){
      this.offset = offset
      this.search()
    },
    async search(){
      let query = {}
      if(this.keyword) query["keyword"] = this.keyword
      if(this.status) query["status"] = this.status
      if(this.grade) query["grade"] = this.grade
      if(this.mentor) query["mentor_uid"] = this.mentor
      if(this.entry_year) query["entry_year"] = this.entry_year
      if(this.leave_year) query["leave_year"] = this.leave_year
      
      if(JSON.stringify({...query,offset: this.offset}) !== JSON.stringify(this.$route.query))
        this.$router.replace({query:{...query,offset: this.offset}})
      this.loading = true
      var result = await this.$services.search.getSearchResult(query, this.offset, this.limit);
      this.loading = false
      this.totalPage = result.total_page
      this.people = result.data
    },
    goDetail(row){
      this.$router.push(`/Profile/${row.uid}`)
    }
  },
  async mounted(){
    if(this.$route.query.keyword) this.keyword = this.$route.query.keyword
    if(this.$route.query.status) this.status = this.$route.query.status
    if(this.$route.query.grade) this.grade = this.$route.query.grade
    if(this.$route.query.mentor) this.mentor = this.$route.query.mentor
    if(this.$route.query.entry_year) this.entry_year = this.$route.query.entry_year
    if(this.$route.query.leave_year) this.leave_year = this.$route.query.leave_year
    if(this.$route.query.offset) this.offset = this.$route.query.offset

    const current_year = new Date().getFullYear();
    for(var i = current_year; i >= 2000; i--){
      this.yearOption.push(i)
    }
    var result = await this.$services.search.getSearchResult({'grade': 'teacher'}, 0, -1);
    this.teacherOptions = result.data.map(x => ({'key': x.uid, 'text': x.name}))
    this.search();
  }
};
</script>
<style scoped lang="scss">

#global-search{
  padding: 33px 15% 33px 8%;
  height: 100vh;
}
.page-title{
  margin: 0 0 10px 0;
  color: var(--v-pageTitle-base);
  font-size: 25px;
  font-weight: bold;
  text-align: left;
}
.gs-search-basic{
  height: 55px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  border-radius: 8px;
  border: solid 2px rgba(92, 92, 92, 0.3);
  background-color: #ffffff;
  .gs-input{
    color: black;
    width: 700px;
    padding: 5px;
    margin: 10px;
  }
  .gs-select{
    width: 200px;
  }
  .gs-divider{
    margin-right: 10px;
    border-right: solid 1.5px rgba(92, 92, 92, 0.3);
  }
  .gs-search-btn{
    width: 100px;
    padding: 6px 32px 6px 33px;
    border-radius: 12px;
    margin: 10px;
  }
  input::-webkit-input-placeholder {
    text-align: left;
    color: #cfcfcf;
  }
  input:focus{
    outline: none;
  }
}

.gs-search-advanced{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin:  10px 0 0 0;
  .gs-select{
    ::v-deep .v-input__slot{
      border: solid 2px rgba(92, 92, 92, 0.3);
      border-radius: 8px;
    }
    margin-right:10px;
    width: 0;
  }
}


.gs-card-container{
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, 160px);
  justify-content: space-between;
  grid-gap: 20px;
}

.gs-table {
  ::v-deep tr {
    cursor: pointer;
  }
  .gs-status{
    display: inline-block;
    height: 8px;
    width: 8px;
    border-radius: 100%;
    margin-right: 8px;
    margin-bottom: 1px;
  }
}

</style>
