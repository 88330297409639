export default class HistoryProjectService {
  constructor(api) {
    this.api = api;
  }
  getAllHistoryProject = async(offset, limit, before_year, project_name, teacher_name, ) => {
    return await this.api.get(`/project`, {
      before_year: before_year, name: project_name, teacher_name: teacher_name, offset: offset, limit: limit
    });
  }
  getHistoryProjectByYear = async (year, project_name, teacher_name, offset, limit) =>{
    return await this.api.get(`/project`, {
      year: year, name: project_name, teacher_name: teacher_name, offset: offset, limit: limit
    });
  }

  getProjectYear = async() => {
    const data = await this.api.get(`/project/config`);
    return data.data;
  }
  getSingleHistoryProject = async(pid) => {
    return await this.api.get(`/project/${pid}`)
  }
  patchSingleHistoryProject = async(pid, project) => {
    return await this.api.patch(`/project/${pid}`, project)
  }
}
