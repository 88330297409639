<template>
    <table>
      <thead>
        <tr>
          <th colspan="3" class="top-left-corner top-right-corner">
            <div class="table-title">
              <span>課程與學分</span>
              <v-icon
                v-if="!isEditMode && (isAdmin)"
                small
                color="#FFFFFF"
                class="edit-btn"
                @click="changeMode"
              >
                mdi-pencil
              </v-icon>
              <v-icon
                v-else-if="isAdmin"
                small
                color="#FFFFFF"
                class="save-btn"
                @click="saveEdit"
              >
                mdi-check-bold
              </v-icon>
            </div>
          </th>
        </tr>
        <tr>
          <th>應修學分</th>
          <th colspan="2">系辦審核</th>
        </tr>
      </thead>
      <tbody v-if="!isEditMode">
        <tr v-for="(item, idx) in academicData.basic_courses" :key="item.cid">
          <td>
            {{ item.subject_name }}
          </td>
          <td>
            {{ resultOptions[item.confirm] }}
          </td>
          <td
            v-if="idx === 0 && academicData.basic_courses"
            :rowspan="academicData.basic_courses.length"
          >
            {{ resultOptions[overallResult] }}
          </td>
        </tr>
        <tr>
          <td colspan="4" class="bottom-left-corner bottom-right-corner note">
            {{ academicData.course_note ? academicData.course_note : '/' }}
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr v-for="(item, idx) in academicData.basic_courses" :key="item.cid">
          <td>{{ item.subject_name }}</td>
          <td class="radio-cell">
            <v-radio-group v-model="item.confirm">
              <v-radio
                v-for="n in resultOptions.length"
                :key="n"
                :label="resultOptions[n - 1]"
                :value="n - 1"
                class="radio-style"
                color="blue"
              ></v-radio>
            </v-radio-group>
          </td>
          <td
            v-if="idx === 0 && academicData.basic_courses"
            :rowspan="academicData.basic_courses.length"
          >
            <div class="radio-cell">
              <v-radio-group v-model="overallResult">
                <v-radio
                  v-for="n in resultOptions.length"
                  :key="n"
                  :label="resultOptions[n - 1]"
                  :value="n - 1"
                  class="radio-style"
                  color="blue"
                ></v-radio>
              </v-radio-group>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="4" class="bottom-left-corner bottom-right-corner note">
            <div class="note-textarea">
              <textarea
                placeholder="請輸入備註"
                v-model="academicData.basic_course_note"
              />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </template>
  
  <script>
  import { mapGetters } from 'vuex';
  export default {
    data() {
      return {
        isEditMode: false,
        resultOptions: ['警告', '通過'],
      };
    },
    computed: {
      ...mapGetters(['academicData', 'currentUID', 'isAdmin', 'warningText']),
      overallResult() {
        const confirmed_courses = this.academicData.basic_courses.filter(
          courses => courses.confirm === 1 || courses.confirm === true,
        );
        if (confirmed_courses.length === this.academicData.basic_courses.length) {
          return 1;
        }
        return 0;
      },
    },
    methods: {
      changeMode() {
        this.isEditMode = !this.isEditMode;
      },
      async saveEdit() {
        const courseList = this.academicData.basic_courses.map(item => {
          return {
            cid: item.cid,
            confirm:
              item.confirm == undefined
                ? undefined
                : item.confirm === 1
                ? true
                : false,
            basic_course_note: '',
          };
        });
        const basicCoursePhd = {
          basic_courses: courseList,
          basic_course_note: this.academicData.basic_course_note,
        };
        await this.$services.academic.saveBasicCoursePhd(
          this.academicData.uid,
          basicCoursePhd,
        );
        if (!this.warningText) {
          this.isEditMode = !this.isEditMode;
        }
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  @import '@/assets/scss/academy.scss';
  </style>
  