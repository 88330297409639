export default class AnnouncementService {
  constructor(api) {
    this.api = api;
  }
  getAnnouncements(category = 'newest', offset = 0, limit = 15) {
    return this.api.get('/announcement', {
      category: category,
      offset: offset,
      limit: limit,
    });
  }
  createAnnouncement(announcement) {
    return this.api.post('/announcement', announcement);
  }
  getAnnouncementById(aid) {
    return this.api.get(`/announcement/${aid}`);
  }
  patchAnnouncement(aid, newAnnouncement) {
    return this.api.patch(`/announcement/${aid}`, newAnnouncement);
  }
}
