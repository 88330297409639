<template>
  <div id="personal-profile">
    <div class="profile-info">
      <div>{{currentName}} ({{currentUID}})</div>
      <div>{{currentType}} | {{currentAuth}} | {{currentStatus}} </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'personal-profile',
  computed: mapGetters(['currentName', 'currentUID', 'currentStatus', 'currentAuth', 'currentType']),
};
</script>

<style lang="scss" scoped>
#personal-profile {
  border-radius: 16px;
  background-color: rgba(100,100,100, 0.3);
  padding: 20px 0;
  box-shadow: 1px 1px 6px 0 rgba(0, 0, 0, 0.06);
  font-size: 16px;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.profile-info {
  width: 100%;
  text-align: center;
  div{
    color: var(--v-lightGrey-base);
    &:first-child{
      font-weight: bold;
    }
  }
}
</style>
