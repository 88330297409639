<template>
  <div id="announcement-detail">
    <v-icon
      v-if="!isEditMode & isAdmin"
      small
      color="#FFFFFF"
      class="edit-btn"
      @click="edit"
    >
      mdi-pencil
    </v-icon>
    <v-icon
      v-else-if="isAdmin"
      small
      color="white"
      class="finish-edit-btn"
      @click="saveEdit"
    >
      mdi-check-bold
    </v-icon>
    <div v-if="!isEditMode" class="not-edit-mode">
      <div class="text-h4 text-start mb-2">{{ announcement.title }}</div>
      <div class="text-h6 text-start darkGrey2--text mb-4">
        <v-chip
          label
          class="white--text mr-2 font-weight-bold" 
          color="var(--v-red-base)"
        >
          {{ announcement.author_name }}
        </v-chip>
       {{ formatTime }}</div>
      <v-divider class="mb-4"></v-divider>
      <p class="content" v-html="announcement.content"></p>
    </div>
    <div v-else class="edit-mode">
      <div class="text-h6 text-start font-weight-bold mb-2">
        標題
      </div>
      <input placeholder="請輸入標題" v-model="announcement.title" />
      <div class="text-h6 text-start font-weight-bold mb-2">
        內容 (可輸入HTML)
      </div>
      <textarea placeholder="請輸入內容" v-model="announcement.content" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
export default {
  name: 'announcement-detail',
  data() {
    return {
      announcement: {
      },
      isEditMode: false,
      aid: 0,
    };
  },
  computed: {
    ...mapGetters(['announcementList', 'isAdmin']),
    formatTime(){
      return moment(this.announcement.announce_time).format('DD-MMMM YYYY');
    }
  },
  methods: {
    edit() {
      this.isEditMode = !this.isEditMode;
    },
    async patchAnnouncement() {
      const newAnnouncementData = this.announcement;
      await this.$services.announcement.patchAnnouncement(
        this.aid,
        newAnnouncementData,
      );
    },
    saveEdit() {
      this.isEditMode = !this.isEditMode;
      // patch announcement with announcement ID
      this.patchAnnouncement();
    },
    async setAnnouncementData() {
      const {
        data: announcement,
      } = await this.$services.announcement.getAnnouncementById(this.aid);
      this.announcement = announcement;
    },
  },
  mounted() {
    this.aid = this.$route.params.id;
    this.setAnnouncementData();
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/announcement.scss';
</style>
