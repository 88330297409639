// this file is only for testing whether vuex is working, should be removed in the future
export default class AuthService {
  constructor(api) {
    this.api = api;
  }
  getInfo() {
    return this.api.get('/auth/info');
  }
  /* this should be removed in production */
  getList() {
    return this.api.get('/auth/b4ckd0or/list');
  }
  /* end */
  logout() {
    return this.api.get('/auth/logout');
  }
}
