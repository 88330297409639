<template>
  <div class="project-container">
    <div class="project-tag">{{ project.teacher_name }}老師 {{project.year - 1911}} 學年度專題</div>
    <div class="project-main">
      <div v-show="hasAuthToEdit" class="project-icon-container">

        <v-icon v-if="!isEditMode" :size="33" class="project-icon red-icon" 
         :src="require('../../assets/red-edit.svg')"
          @click.native="isEditMode = true"> mdi-pencil-circle </v-icon>

        <v-icon :disabled="(this.currentProjectStage == '3' || this.currentProjectStage == '4' )" v-if="!isEditMode" :size="33" class="project-icon" 
        @click.native="deleteProject(dataProject.pid)" >mdi-delete</v-icon>

        <v-icon :size="33" class="project-icon"
          v-else
          @click="patchProject(dataProject.pid, dataProject)">  
          mdi-checkbox-marked-circle 
        </v-icon>
      </div>
<!--         <div class="project-item project-item-small">
          <div class="project-title">授課教授</div>
          <div class="project-static-content">{{ project.teacher_name }} </div>
        </div> -->
        <div class="project-item project-item-small">
          <div class="project-title">專題名稱</div>
          <input :disabled="!isEditMode" v-model="dataProject.title" class="project-content">
        </div>
        <div class="project-item project-item-big">
          <div class="project-title">專題內容</div>
          <textarea :disabled="!isEditMode" v-model="dataProject.description" class="project-content"></textarea>
        </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  props: { 
    project: { type: Object, default: function () { return {} } },
    hasAuthToEdit: { type: Boolean, default: false }
  },
	name: 'project',
	data() {
		return {
      isEditMode: false,
      dataProject: this.project
		};
	},
  computed:{
    ...mapGetters(['routingList', 'currentProjectStage'])
  },
  methods: {
    ...mapActions(['deleteProjectOnStageOne', 'patchProjectOnStageOne']),
    async deleteProject(pid){
      await this.$services.projectManage.deleteProjectOnStageOne(pid);
      this.deleteProjectOnStageOne(pid)
    },
    async patchProject(pid, project){
      delete project['members'];
      delete project['pid'];
      this.isEditMode = false
      await this.$services.projectManage.patchProjectOnStageOne(pid, project);
      this.patchProjectOnStageOne(pid, project)
    }
  }
};
</script>
<style scoped>
.project-container{
	width: 100%;
	height: 300px;
  align-items:flex-start;
  justify-content:flex-start;
  flex-wrap:nowrap;
}
.project-tag{
  position: relative;
	width: 250px;
  height: 35px;
  background-color: var(--v-blue-base);
  padding: 7px;
  color: var(--v-white-base);
  font-weight: bold;
  letter-spacing: 1.28px;
  margin-bottom: -15px;
  margin-left: 25px;
  z-index: 10;
}
.project-main{
  height: 250px;
  background: var(--v-white-base);
  z-index: -1;
  padding: 20px 30px 20px 30px;
  justify-content: space-around;
  display: flex;
  flex-direction: column;
  border-radius: 24px;
}

.project-item{
  display: flex;
  flex-direction: row;
  margin: 10px;
}
.project-item-small{
  height: 40px;
}
.project-item-big{
  height: 160px;
}
.project-title{
  background-color: var(--v-blue-base);
  width: 100px;
  height: 100%;
  color: var(--v-white-base);
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1.02px;
  text-align: center;
  color: var(--v-white-base);
  padding: 10px;
  border-radius: 12px 0px 0px 12px;
  background-color: var(--v-blue-base);
}
textarea {
  resize: none;
}
.project-static-content{
  flex-grow: 1;
  background: rgba(200, 200, 200, 0.15);
  height: 100%;
  margin: 0px 0 10px 15px;
  padding: 10px 0px 0px 5px;
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  color: var(--v-darkGrey-base);
  flex-wrap:wrap;
  word-wrap:normal;
}
.project-content:disabled{
  border: 0px;
}
.project-content{
  background: rgba(200, 200, 200, 0.15);
  outline-width: thin;
  outline-color: var(--v-blue-base); 
  border:2px solid var(--v-blue-base);
  flex-grow: 1;
  height: 100%;
  margin: 0px 0 10px 15px;
  padding: 0px 0px 0px 5px;
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  color: var(--v-darkGrey-base);
  flex-wrap:wrap;
  word-wrap:normal;
}

.project-icon-container{
  display: flex;
  justify-content: flex-end;
}
.project-icon{
  color:var(--v-blue-base); 
  margin-right: 5px;
}
.project-icon:hover{
  cursor: pointer;
}
.red-icon{
  color:var(--v-red-base); 
}
</style>