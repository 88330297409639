<template>
  <v-app id="app">
    <NavBar v-if="$route.name !== 'home'"/>
    <ErrorSnackBar />
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import NavBar from './components/NavBar';
import ErrorSnackBar from './components/ErrorSnackBar.vue'
export default {
  components: {
    NavBar,
    ErrorSnackBar
  },
  name: 'App',
};
</script>

<style>
html{
  overflow: auto;
}
body {
  margin: 0;
  padding: 0;
  background-color: var(--v-lightGrey2-base);
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: var(--v-lightGrey2-base);
}
</style>

