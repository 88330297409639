import TestService from './testService'; // should be removed
import AuthService from './authService';
import AnnouncementService from './announcementService';
import AdministrationService from './administrationService';
import ProfileService from './profileService';
import AcademicService from './academicService';
import axios from 'axios';
import config from '../../config';
import ProjectManageService from './projectManageService';
import HistoryProjectService from './historyProjectService';
import GlobalSearchService from './globalSearchService';

class Services {
  install(Vue, { store }) {
    const api = new Config(store);
    this.test = new TestService(api); // should be removed
    this.projectManage = new ProjectManageService(api);
    this.auth = new AuthService(api);
    this.announcement = new AnnouncementService(api);
    this.administration = new AdministrationService(api);
    this.historyProject = new HistoryProjectService(api);
    this.profile = new ProfileService(api);
    this.academic = new AcademicService(api);
    this.search = new GlobalSearchService(api);
    Vue.prototype.$services = {
      test: this.test,
      auth: this.auth,
      projectManage: this.projectManage,
      announcement: this.announcement,
      administration: this.administration,
      historyProject: this.historyProject,
      profile: this.profile,
      academic: this.academic,
      search: this.search,
    };
  }
}

class Config {
  constructor(store) {
    this.store = store;
    this.baseUrl = config.BASEURL;
    this.headers = {
      'Access-Control-Allow-Origin': '*',
    };
  }
  sendRequest(method, url, data = null, headers = this.headers) {
    // Comment out because not sure why should we do this
    // this.store.commit('setWarningText', '');
    return new Promise((resolve, reject) => {
      this.store.commit('setLoading', true);
      axios({
        method,
        url: `${this.baseUrl}${url}`,
        params: method === 'GET' ? data : null,
        data: method === 'GET' ? null : data,
        headers,
        withCredentials: true,
      })
        .then(response => {
          if (response.status === 200) {
            resolve(response.data);
          }else {
            reject(response.error);
          }
        })
        .catch(err => {
          if (err.response.status === 401 && location.pathname != '/'){
            location = '/';
          }
          this.store.commit('setWarningText', err.toString());
          this.store.commit('setErrorHappenend', true);
          reject(err);
        })
        .then(() => {
          this.store.commit('setLoading', false);
        });
    });
  }
  get(url, request = {}, options = {}) {
    return this.sendRequest('GET', url, request, options);
  }
  post(url, request = {}, options = {}) {
    return this.sendRequest('POST', url, request, options);
  }
  patch(url, request = {}, options = {}) {
    return this.sendRequest('PATCH', url, request, options);
  }
  delete(url, request = {}, options = {}) {
    return this.sendRequest('DELETE', url, request, options);
  }
}

export default new Services();
