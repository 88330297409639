export default {
  isLoading: false,
  isLoggedIn: false,
  isErrorHappenend: false,
  warningText: null,
  isNotify: false,
  notificationText: 'this is a notification text',
  user: {},
  projects: [],
  projectYears: [],
  currentProjectYear: new Date().getFullYear().toString(),
  stageTimeline: {},
  selectedAnnouncementTab: 'newest',
  announcementList: {},
  academicData: {
    course_config: {},
    proposal_details: [],
    degree_exam_details: [],
    oral_exams: [],
    progress_checks: [],
    paper_points: [],
    qualify_subject_exam: [],
  },
  currentProjectStage: '1',
  routingList: {
    '0': 'stageOne',
    '1': 'stageOne',
    '2': 'stageTwo',
    '3': 'stageThree',
    '4': 'stageFour',
  },
  // personalData: {},
  // courseData: {},
};
