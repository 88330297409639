<template>
  <div id="announcement-list">
    <div v-if="!(selectedAnnouncementTab === 'add')" class="card-container">
      <router-view />
      <div
        class="card-group"
        v-for="(item, idx) in announcementList.data"
        :key="idx"
      >
        <v-hover v-slot="{ hover }">
          <div class="card" :class="`elevation-${hover ? 6 : 3}`" @click="goToDetail(item.aid)">
            <div class="author">
              <!-- <img :src="question" /> -->
    <v-btn
      class="mr-8 font-weight-bold"
      :class="`tab-${item.category}`"
      text-color="white"
      icon
      large
    >
      {{tabToChinese[item.category].substr(0,2)}}
    </v-btn>
              <div>{{item.author_name}}</div>
            </div>
            <div class="card-title text-h6">{{ (item.title||'').substr(0,40) + ((item.title||'').length > 40 ?'...': '') }}</div>
            <div class="card-date">{{ item.announce_time }}</div>
          </div>
        </v-hover>
      </div>
    </div>

    <form
      v-else
      class="card-container add-announcement"
      @submit.prevent="createAnnouncement"
    >
      <div class="form-item-container select-style">
        <div class="form-label">公告類別</div>
        <div class="form-item">
          <v-select
            v-model="newAnnouncementType"
            :items="typeOptions"
            label="公告類別"
            color="blue"
            item-color="blue"
          ></v-select>
        </div>
      </div>
      <div class="form-item-container">
        <div class="form-label">公告標題</div>
        <input v-model="newAnnouncementTitle" placeholder="請輸入公告標題" />
      </div>
      <div class="form-item-container">
        <div class="form-label">公告內容</div>
        <textarea
          placeholder="請輸入公告內容"
          v-model="newAnnouncementContent"
        />
      </div>
      <div class="submit-button-container">
        <v-btn type="submit" value="Submit" outlined color="var(--v-blue-darken1)" :disabled="(!newAnnouncementTitle)||(!newAnnouncementContent)">Submit</v-btn>
        <input type="reset" value="Clear" />
      </div>
    </form>

    <Pagination
      v-if="announcementList.total_page"
      :currentTotalPage="announcementList.total_page"
      :limit="announcementList.limit"
      @changePage="offset => this.changePage(offset)"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import config from '../../../config';
import moment from 'moment';
import Pagination from '../../components/ProjectManage/Pagination.vue';
export default {
  name: 'announcement',
  components: { Pagination },
  data() {
    return {

      typeOptions: config.announcementTypeItems,
      newAnnouncementType: config.announcementTypeItems[0],
      newAnnouncementTitle: '',
      newAnnouncementContent: '',

      offset: 0,
      tabToChinese: {
        newest: '最新消息',
        department: '系辦公告',
        recruiting: '企業徵才',
        teacher: '教師專區',
        add: '新增公告',
      },
    };
  },
  computed: {
    ...mapGetters([
      'selectedAnnouncementTab',
      'announcementList',
      'currentUID',
    ]),
  },
  methods: {
    ...mapActions(['changeAnnouncementTab', 'setAnnouncementList']),
    changePage(offset) {
      this.offset = offset;
      this.getAnnouncementList(this.$route.params.tab);
    },
    goToDetail(id) {
      this.$router.push({
        name: 'announcement-detail',
        params: { id: id },
      });
    },
    checkNewAnnounce() {
      return;
    },
    async getAnnouncementList(category) {
      if(category == 'add') return;
      else if(category == 'newest') category = null;
      const { data:announcements } = await this.$services.announcement.getAnnouncements(
        category,
        this.offset,
      );
      announcements.data.forEach((announcement, idx) => {
        announcements.data[idx].announce_time = moment(
          announcement.announce_time,
        ).format('YYYY-MM-DD hh:mm');
      });
      this.setAnnouncementList(announcements);
    },
    async createAnnouncement() {
      const tabMapping = Object.freeze({
        系辦公告: 'department',
        企業徵才: 'recruiting',
        教師專區: 'teacher',
      });
      const announcement = {
        title: this.newAnnouncementTitle,
        content: this.newAnnouncementContent,
        category: tabMapping[this.newAnnouncementType],
        markdown: 'string',
        author_uid: this.currentUID,
      };
      const res = await this.$services.announcement.createAnnouncement(
        announcement,
      );
      if (res) {
        this.newAnnouncementTitle = '';
        this.newAnnouncementContent = ''
        this.changeAnnouncementTab(tabMapping[this.newAnnouncementType]);
        this.$router.push({
          path: `/Announcement/list/${tabMapping[this.newAnnouncementType]}`,
        });
        this.getAnnouncementList(tabMapping[this.newAnnouncementType]);
      }
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/announcement.scss';
</style>
