<template>
  <table>
    <thead>
      <tr>
        <th colspan="5" class="top-left-corner top-right-corner">
          <div class="table-title">
            <span>{{
              grade === 'mba' ? '碩士論文研究計畫書' : '博士論文研究計畫書'
            }}</span>
            <v-icon
              v-if="!isEditMode && (isAdmin)"
              small
              color="#FFFFFF"
              class="edit-btn"
              @click="changeMode"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              v-else-if="isAdmin"
              small
              color="#FFFFFF"
              class="save-btn"
              @click="saveEdit"
            >
              mdi-check-bold
            </v-icon>
          </div>
        </th>
      </tr>
      <tr>
        <th>狀態</th>
        <th>論文題目</th>
        <th>日期</th>
        <th colspan="2">系辦審核</th>
      </tr>
    </thead>
    <tbody v-if="!isEditMode && academicData.proposal_details">
      <tr
        v-for="(item, idx) in academicData.proposal_details"
        :key="idx"
      >
        <td>{{ paperResultOptions[item.confirm] }}</td>
        <td>{{ item.subject ? item.subject : '/' }}</td>
        <td>{{ item.date }}</td>
        <td
          v-if="idx === 0 && academicData.proposal_details"
          :rowspan="academicData.proposal_details.length"
        >
          {{ overallResultOptions[proposalConfirm] }}
        </td>
      </tr>

      <tr>
        <td colspan="5" class="bottom-left-corner bottom-right-corner note">
          {{ academicData.proposal_note ? academicData.proposal_note : '/' }}
        </td>
      </tr>
    </tbody>
    <tbody v-else>
      <tr
        v-for="(item, idx) in academicData.proposal_details"
        :key="idx"
      >
        <td>
          <v-radio-group v-model="item.confirm"
            ><v-radio
              v-for="n in paperResultOptions.length"
              :key="n"
              :label="paperResultOptions[n - 1]"
              :value="n - 1"
              class="radio-style"
              color="green"
            ></v-radio>
          </v-radio-group>
        </td>
        <td>
          <div class="note-textarea">
            <textarea placeholder="請輸入論文題目" v-model="item.subject" />
          </div>
        </td>
        <td>
          <DatePicker
            color="green"
            @saveDate="saveDate"
            :datePicked="item.date"
            :idx="idx"
          />
        </td>
        <td
          v-if="idx === 0 && academicData.proposal_details"
          :rowspan="academicData.proposal_details.length"
        >
          <v-radio-group v-model="proposalConfirm"
            ><v-radio
              v-for="n in overallResultOptions.length"
              :key="n"
              :label="overallResultOptions[n - 1]"
              :value="n - 1"
              class="radio-style"
              color="green"
            ></v-radio>
          </v-radio-group>
        </td>
      </tr>

      <tr
        v-if="
          academicData.proposal_details &&
            academicData.proposal_details.length < 2
        "
      >
        <td colspan="5">
          <v-icon small color="#FFFFFF" class="plus-btn" @click="addProposal">
            mdi-plus
          </v-icon>
        </td>
      </tr>

      <tr>
        <td colspan="5" class="bottom-left-corner bottom-right-corner note">
          <div class="note-textarea">
            <textarea
              placeholder="請輸入備註"
              v-model="academicData.proposal_note"
            />
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import DatePicker from '@/components/DataPicker.vue';
import { mapGetters } from 'vuex';
import moment from 'moment';
const paperResultOptions = ['不通過', '通過'];
export default {
  props: {
    grade: { type: String, default: 'mba' },
  },
  components: {
    DatePicker,
  },
  data() {
    return {
      isEditMode: false,
      paperResultOptions: paperResultOptions,
      overallResultOptions: ['警告', '通過'],

      // paperResult: '',
      // overallResult: '',
      // paperDetail: '',
      // datePicked: '',
      // note: '',
    };
  },
  computed: {
    ...mapGetters(['academicData', 'currentUID', 'isAdmin', 'warningText']),
    proposalConfirm: {
      get: function() {
        return this.academicData.proposal_confirm ? 1 : 0;
      },
      set: function(value) {
        this.academicData.proposal_confirm = value === 1 ? true : false;
      },
    },
  },
  methods: {
    changeMode() {
      this.isEditMode = !this.isEditMode;
    },
    saveDate(date, idx) {
      this.academicData.proposal_details[idx].date = date;
    },
    addProposal() {
      this.academicData.proposal_details.push({
        uid: this.academicData.uid,
        subject: '',
        date: moment().format('YYYY-MM-DD'),
        confirm: undefined,
      });
    },
    async saveEdit() {
      this.academicData.proposal_details = this.academicData.proposal_details.filter(
        proposal => proposal.subject,
      );
      const proposalList = this.academicData.proposal_details.map(proposal => {
        return {
          uid: proposal.uid,
          subject: proposal.subject,
          date: moment(proposal.date).format('YYYY-MM-DDT23:59:59') + 'Z',
          confirm:
            proposal.confirm == undefined
              ? undefined
              : proposal.confirm === 1
              ? true
              : false,
        };
      });
      const proposal = {
        proposals: proposalList,
        proposal_confirm: this.academicData.proposal_confirm,
        proposal_note: this.academicData.proposal_note,
      };
      await this.$services.academic.saveProposal(
        this.grade,
        this.academicData.uid,
        proposal,
      );
      if (!this.warningText) {
        this.isEditMode = !this.isEditMode;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/scss/academy.scss';
.select-style {
  width: 100px;
}
</style>
