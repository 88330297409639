import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#9ed3fd',
        pageTitle: '#364e6b',
        darkGrey: '#6d6d6d',
        darkGrey2: '#a6a6a6',
        lightGrey: '#c3c3c3',
        lightGrey2: '#F5F5F5',
        lightGrey3: '#e3e3e3',
        secondary: '#b0bec5',
        accent: '#8c9eff',
        pink: '#fd9e9e',
        blue: '#9ed3fd',
        orange: '#ffbc6f',
        green: '#84e0be',
        purple: '#7e7ef4',
        white: colors.shades.white,
        error: '#b71c1c',
        red: '#fd9e9e',
        black: '#000000',
      },
      dark: {
        primary: colors.lightBlue.white,
        secondary: '#b0bec5',
        accent: '#8c9eff',
        white: colors.shades.white,
        error: '#b71c1c',
      },
    },
  },
});
