<template>
  <div id="announcement">
    <div class="page-head">
      <div>
        <div class="page-title">公告特區</div>
        <div class="tab-group">
          <div v-for="(ch, tab) in tabToChinese" :key="tab">
            <div
              class="tab"
              :class="`tab-${tab} ${selectedAnnouncementTab==tab?'isSelected':''}`"
              @click="changeTab(tab)"
            >
              {{ch}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="tab-title"
      :class="`tab-${selectedAnnouncementTab}`"
    >
      {{ tabToChinese[selectedAnnouncementTab] }}
    </div>

    <router-view />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import config from '../../../config';
export default {
  name: 'announcement',
  components: {},
  data() {
    return {
      tabToChinese: {
        newest: '最新消息',
        department: '系辦公告',
        recruiting: '企業徵才',
        teacher: '教師專區',
        add: '新增公告',
      },
      typeOptions: config.announcementTypeItems,
      newAnnouncementType: config.announcementTypeItems[0],
      newAnnouncementTitle: '',
      newAnnouncementContent: '',
    };
  },
  computed: {
    ...mapGetters(['selectedAnnouncementTab', 'isAdmin']),
  },
  methods: {
    ...mapActions(['changeAnnouncementTab', 'setAnnouncementList']),
    async getAnnouncementList(category) {
      if(category == 'add') {
        this.setAnnouncementList([]);
      }else if(category == 'newest') category = null;
      const {
        data: announcements,
      } = await this.$services.announcement.getAnnouncements(category);
      announcements.data.forEach((announcement, idx) => {
        announcements.data[idx].announce_time = moment(
          announcement.announce_time,
        ).format('YYYY-MM-DD hh:mm');
      });
      this.setAnnouncementList(announcements);
    },
    changeTab(newTab) {
      if (newTab === this.selectedAnnouncementTab) return;
      this.changeAnnouncementTab(newTab);
      this.tabTitleBarChineseName = this.tabToChinese[newTab];
      this.$router.push({ path: `/Announcement/list/${newTab}` });
      this.getAnnouncementList(newTab);
    },
    goToDetail(id) {
      this.$router.push({
        path: `/Announcement/detail/${id}`,
      });
    },
  },
  mounted() {
    this.changeAnnouncementTab(this.$route.params.tab);
    this.getAnnouncementList(this.$route.params.tab);
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/announcement.scss';
</style>
