//import state from './state';

export const loggedIn = state => {
  return state.isLoggedIn;
};

export const currentGrade = state => {
  return state.user.grade;
}

export const currentType = state => {
  const typeMap = {
    bba: '學士班',
    mba: '碩士班',
    phd: '博士班',
    facalty: '教職員',
  };
  return typeMap[state.user.grade];
};

export const currentName = state => {
  return state.user.name;
};

export const currentUID = state => {
  return state.user.uid;
};

export const currentStatus = state => {
  const statusMap = {
    present: '在學',
    double: '雙主修',
    transfer: '轉系',
    suspend: '休學',
    dropout: '離校',
    alumni: '畢業',
    leave: '離職',
  };
  return statusMap[state.user.status];
};

export const currentAuth = state => {
  const authMap = {
    admin: '管理員',
    teacher: '老師',
    student: '學生',
  };
  return authMap[state.user.auth];
};

export const projects = state => {
  return state.projects;
};

export const projectYears = state => {
  return state.projectYears;
};

export const currentProjectYear = state => {
  return state.currentProjectYear;
};

export const projectTimeline = state => {
  return state.stageTimeline;
};

export const selectedAnnouncementTab = state => {
  return state.selectedAnnouncementTab;
};

export const announcementList = state => {
  return state.announcementList;
};

export const academicData = state => {
  return state.academicData;
};

// export const personalData = state => {
//   return state.personalData;
// };

// export const courseData = state => {
//   return state.courseData;
// };
export const isAdmin = state => {
  return state.user.auth == 'admin';
};

export const isStudent = state => {
  return state.user.auth == 'student';
};

export const isTeacher = state => {
  return state.user.auth == 'teacher';
};

export const isErrorHappenend = state => {
  return state.isErrorHappenend;
};

export const warningText = state => {
  return state.warningText;
};

export const isNotify = state => {
  return state.isNotify;
};

export const notificationText = state => {
  return state.notificationText;
};

export const currentProjectStage = state => {
  return state.currentProjectStage;
}
export const routingList = state => {
  return state.routingList;
}

export const studentOverSecondDisabled = state => {
  if(Number(state.currentProjectStage) >= 3 && state.user.auth == 'student') return true
  else return false
}

export const isLoading = state => {
  return state.isLoading;
}
