<template>
  <table>
    <thead>
      <tr>
        <th colspan="4" class="top-left-corner top-right-corner">
          <div class="table-title">
            <span>學術成果進度審查</span>
            <v-icon
              v-if="!isEditMode && (isAdmin)"
              small
              color="#FFFFFF"
              class="edit-btn"
              @click="changeMode"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              v-else-if="isAdmin"
              small
              color="#FFFFFF"
              class="save-btn"
              @click="saveEdit"
            >
              mdi-check-bold
            </v-icon>
          </div>
        </th>
      </tr>
      <tr>
        <th>狀態</th>
        <th>論文題目</th>
        <th>日期</th>
        <th>系辦審核</th>
      </tr>
    </thead>
    <tbody v-if="!isEditMode">
      <tr v-for="(item, idx) in academicData.progress_checks" :key="idx">
        <td>{{ paperResultOptions[item.is_passed] }}</td>
        <td>{{ item.note ? item.note : '/' }}</td>
        <td>{{ item.date }}</td>
        <td>{{ overallResultOptions[item.confirm] }}</td>
      </tr>
      <tr>
        <td colspan="4" class="bottom-left-corner bottom-right-corner note">
          {{
            academicData.progress_check_note
              ? academicData.progress_check_note
              : '/'
          }}
        </td>
      </tr>
    </tbody>
    <tbody v-else>
      <tr v-for="(item, idx) in academicData.progress_checks" :key="item.note">
        <td class="radio-cell">
          <v-radio-group v-model="item.is_passed">
            <v-radio
              v-for="n in paperResultOptions.length"
              :key="n"
              :label="paperResultOptions[n - 1]"
              :value="n - 1"
              actice-class="radio-style"
              color="blue"
            ></v-radio>
          </v-radio-group>
        </td>
        <td>
          <div class="note-textarea">
            <textarea placeholder="請輸入論文題目" v-model="item.note" />
          </div>
        </td>
        <td>
          <DatePicker
            color="blue"
            @saveDate="saveDate"
            :datePicked="item.date"
            :idx="idx"
          />
        </td>
        <td class="radio-cell">
          <v-radio-group v-model="item.confirm">
            <v-radio
              v-for="n in overallResultOptions.length"
              :key="n"
              :label="overallResultOptions[n - 1]"
              :value="n - 1"
              actice-class="radio-style"
              color="blue"
            ></v-radio>
          </v-radio-group>
        </td>
      </tr>

      <tr>
        <td colspan="5">
          <v-icon small color="#FFFFFF" class="plus-btn" @click="addProgress">
            mdi-plus
          </v-icon>
        </td>
      </tr>

      <tr>
        <td colspan="4" class="bottom-left-corner bottom-right-corner note">
          <div class="note-textarea">
            <textarea
              placeholder="請輸入備註"
              v-model="academicData.progress_check_note"
            />
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import DatePicker from '@/components/DataPicker.vue';
import { mapGetters } from 'vuex';
import moment from 'moment';
export default {
  components: {
    DatePicker,
  },
  data() {
    return {
      isEditMode: false,
      paperResultOptions: ['不通過', '通過'],
      overallResultOptions: ['警告', '通過'],

      // paperResult: '',
      // overallResult: '',
      // paperDetail: '',
      // datePicked: '',
      // note: '',
    };
  },
  computed: {
    ...mapGetters(['academicData', 'currentUID', 'warningText']),
  },
  methods: {
    changeMode() {
      this.isEditMode = !this.isEditMode;
    },
    addProgress() {
      this.academicData.progress_checks.push({
        uid: this.academicData.uid,
        note: '',
        date: moment().format('YYYY-MM-DD'),
        is_passed: undefined,
        confirm: undefined,
      });
    },
    async saveEdit() {
      this.academicData.progress_checks = this.academicData.progress_checks.filter(
        exam => exam.note,
      );
      const progressList = this.academicData.progress_checks.map(paper => {
        return {
          uid: paper.uid,
          note: paper.note,
          date: moment(paper.date).format('YYYY-MM-DDT23:59:59') + 'Z',
          is_passed:
            paper.is_passed == undefined
              ? undefined
              : paper.is_passed === 1
              ? true
              : false,
          confirm:
            paper.confirm == undefined
              ? undefined
              : paper.confirm === 1
              ? true
              : false,
        };
      });
      const progress = {
        progress_checks: progressList,
        progress_check_note: this.academicData.progress_check_note,
      };
      await this.$services.academic.saveProgress(
        this.academicData.uid,
        progress,
      );
      if (!this.warningText) {
        this.isEditMode = !this.isEditMode;
      }
    },
    saveDate(date, idx) {
      this.academicData.progress_checks[idx].date = date;
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/assets/scss/academy.scss';
.select-style {
  width: 100px;
}
</style>
