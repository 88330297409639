<template v-if="status=='student'">
    <div id="sort-table">
    <div class="st-content-contanier">
      <table class="st-table-main">
        <thead>
          <tr> <th> {{this.selectedTitle}} </th> </tr>
        </thead>
        <tbody>
          <draggable :disabled="this.studentOverSecondDisabled" v-model="selectedState" group="people" @start="drag=true" @end="drag=false">
            <tr v-for="(element, index) in selectedState" :key="element.id">
              
              <td class="st-table-index-100"><v-icon>mdi-drag</v-icon> {{ index + 1 }}</td>
              <td>{{ element.teacher_name }}</td>
            </tr>
          </draggable>
        </tbody>
      </table>
      <table v-show="toBeSelectedState.length != 0 || bufferSearch != 0" class="st-table-assist">
        <thead>
          <tr> <th> {{this.toBeSelectedTitle}} </th> </tr>
        </thead>
        <tbody>
<!--           <tr> 
            <input v-model="searchText" class="st-table-search" type="text" placeholder="搜尋"> 
          </tr> -->
          <draggable :disabled="this.studentOverSecondDisabled" v-model="toBeSelectedState" group="people" @start="drag=true" @end="drag=false">
            <tr v-for="(element, index) in toBeSelectedState" :key="element.id"  @click="click(index)">
              <v-icon>mdi-drag</v-icon>
              <td> {{element.teacher_name}} </td>
            </tr>
          </draggable>
        </tbody>
      </table>
    </div>
    <div class="st-button-contanier">
      <v-btn :disabled="!!toBeSelectedState.length || this.studentOverSecondDisabled"
      class="st-save-btn" color=var(--v-purple-base) elevation="2" height="50px" :loading="loading"
      @click="createPriority()">
      {{this.buttonTitle}}
      </v-btn>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import draggable from 'vuedraggable'
export default {
  components: {
    draggable
  },
  props: { 
    // selectedState:{ type: Array, default: function(){return []}},
    // toBeSelectedState:{ type: Array, default: function(){return []}},
    student_uid: {type: String, default: ""},
    selectedTitle:{ type: String, default: "教授志願排序"},
    toBeSelectedTitle: { type: String, default: "未排序教授列表"},
    buttonTitle:{ type: String, default: "完成排序"},
  },
  name: 'sortTable',
  data() {
    return {
      selectedState: [],
      toBeSelectedState: [],
      bufferSearch: [],
      searchText:"",
      isSelectedChange: false,
      loading: false
    };
  },
  async mounted(){
    await this.getselectedPriority(this.currentProjectYear, this.student_uid)
    await this.getCandidateTeacher(this.currentProjectYear)  
    this.$watch('selectedState', this.selectedStateWatcher);
  },
  watch: {
    searchText:{
      handler(){
        let all = this.toBeSelectedState.concat(this.bufferSearch)
        this.toBeSelectedState = []
        this.bufferSearch = []
        all.forEach(ele => {
          if(ele.name.toLowerCase().includes(this.searchText.toLowerCase())){
            this.toBeSelectedState.push(ele)
          }
          else{
            this.bufferSearch.push(ele)
          }
        })
      }
    },
  },
  methods:{
    selectedStateWatcher(value, newValue){
      if(value != newValue){
          this.isSelectedChange = true
        }
    },
    click(idx){
      this.selectedState.push(this.toBeSelectedState[idx]);
      this.toBeSelectedState.splice(idx, 1);
    },
    async studentCreatePriority(){
      var priorities = []
      for(var i = 0; i < this.selectedState.length; i++){
        priorities.push({
          "year": this.currentProjectYear.toString(),
          "selected_uid": this.selectedState[i].teacher_uid,
          "priority": i+1
        })
      }
      this.loading = true;
      await this.$services.projectManage.createStudentPriority(priorities);
      this.$store.commit('setWarningText', '排序完成！');
      this.$store.commit('setErrorHappenend', true);
      this.loading = false;
    },
    async adminCreatePriority(){
      var result = {"year": this.currentProjectYear.toString(), "student_uid": this.student_uid}
      var priorities = []
      for(var i = 0; i < this.selectedState.length; i++){
        priorities.push({
          "selected_uid": this.selectedState[i].teacher_uid,
          "priority": i+1
        })
      }
      result["priorities"] = priorities
      this.loading = true; 
      await this.$services.projectManage.adminCreateStudentPriority(result);
      this.$store.commit('setWarningText', '已儲存');
      this.$store.commit('setErrorHappenend', true);
      this.loading = false;
    },
    async createPriority(){
      if(this.isStudent) this.studentCreatePriority()
      else {
        this.adminCreatePriority()
        await this.$emit("refreshStatusTable", this.currentProjectYear)
      }
      this.isSelectedChange = false
    },
    async getselectedPriority(year, student_uid){
      this.selectedState = []
      var tmp = []

      if(this.isStudent){
        tmp = await this.$services.projectManage.studentGetSelectedStuPriority(year)
        await tmp.forEach((e) => {
          this.selectedState.push(e)
        })
      } 
      else{
        tmp = await this.$services.projectManage.adminGetSelectedStuPriority(year, student_uid)
        await tmp.data.forEach((e) => {
          this.selectedState.push(e)
        })
      }
      this.selectedState.sort((a, b) => (a.priority > b.priority) ? 1 : -1)
    },
    async getCandidateTeacher(year){
      this.toBeSelectedState = []
      var allTeacher = (await this.$services.projectManage.getAllProjectTeacher(year)).data;
      await allTeacher.forEach(async (element) => {
        if(!this.selectedState.find(ele => ele.teacher_uid == element.teacher_uid)){
          await this.toBeSelectedState.push({
          "teacher_uid": element.teacher_uid,
          "teacher_name": element.teacher_name
        })
        }
      });
    }
  },
  computed: mapGetters(['studentOverSecondDisabled', 'isStudent', 'currentName', 'currentUID','currentProjectYear']),
};
</script>
<style scoped>
#sort-table{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.st-content-contanier{
  width: 100%;
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-column-gap: 50px;
  overflow-x:hidden;
  overflow-y:auto;
}
.st-table-main{
  width: 100%; 
  max-width: 100%;
  max-height: 0;
  overflow-x:hidden;
  overflow-y:auto;
}
.st-table-assist{
  width: 100%; 
  max-height: 0; 
  overflow-x:hidden;
  overflow-y:auto;
}
.st-table-td-200{
  width: 200px;
}
.st-table-td-450{
  width: 450px;
}

table {
  border: 3px solid var(--v-blue-base);
  color: var(--v-darkGrey-base);
  background-color: var(--v-white-base);
  width: 40%;
  font-weight: bold;
}
th {
  background-color: var(--v-blue-base);
  color: var(--v-white-base);
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -user-select: none;
  min-width:120px;
  padding: 10px 20px;
}

tr{
  background-color: var(--v-lightGrey2-base);
}
tr:hover{
  background-color:var(--v-lightGrey-base);
  cursor: pointer;
}
td {
  min-width:120px;
  padding: 10px 20px;
  width: 100%;
}
.st-table-index-100{
  width: 100px;
}
.st-table-search{
  padding: 5px;
  padding-left: 10px;
  background-color: var(--v-white-base);
  outline: none;
  height: 100%;
  width: 100%;
}

::placeholder {
  color: var(--v-lightGrey-base);;
  opacity: 1; /* Firefox */
}

.st-button-contanier{
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  align-self: flex-end;

}
.st-save-btn{
  width: 150px;
  color: var(--v-white-base);
  font-size: 20px;
  font-weight: bold;
  border-radius: 15px;
  height: 50px;
  background-color: var(--v-purple-base);
}

</style>
