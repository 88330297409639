export default class ProjectManageService {
  constructor(api) {
    this.api = api;
  }
  getAvailableProjectYear = async () =>{
    const data = await this.api.get(`/project/config`);
    if (data.data) return data.data;
    else []
  }
  getProjectTimeLine = async (year) => {
    const data = await this.api.get(`/project/config/${year}`);
    return data;
  }

  getStageOneProject = async (year, offset, limit) => {
    const data = await this.api.get('/project',{
      year: year, offset:offset, limit: limit
    }); 
    if (data) return data;
    else []
  }
  createProjectOnStageOne = async (project) => {
    const data  = await this.api.post(`/project`, 
      project
    );
    return data;
  }
  deleteProjectOnStageOne = async (pid) => {
    const data = await this.api.delete(`/project/${pid}`);
    return data;
  }
  patchProjectOnStageOne = async (pid, project) => {
    const data = await this.api.patch(`/project/${pid}`, 
      project
    );
    return data
  }

  // stage two
  getAllProjectTeacher = async (year) => {
    const data = await this.api.get(`/project`,{
      year: year
    })
    if (!data.data) data.data = [];
    return data
  }
  studentGetSelectedStuPriority = async (year) => {
    const data = await this.api.get(`/priority/student/${year}`)
    if (data) return data;
    else []
  }
  adminGetSelectedStuPriority = async (year, student_uid) => {
    const data = await this.api.get(`/priority/student`,{
      year: year, student_uid: student_uid
    })
    if (!data.data) data.data = []
    return data;
  }

  createStudentPriority = async(priority) => {
    priority = JSON.parse(JSON.stringify(priority))
    const data = await this.api.post(`/priority/student/batch`,
      priority
    )
    return data
  }

  adminCreateStudentPriority = async (priority) => {
    priority = JSON.parse(JSON.stringify(priority))
    return await this.api.patch(`/priority/student/batch`,
      priority
    )
  }

  getAllStudentPriorityStatus = async(year, offset, limit) => {
    const data = await this.api.get(`/priority/student/status/${year}`,{
      offset: offset, limit: limit
    })
    if (data) return data;
    else []
  }

  // stage three
  getTeacherOwnProject = async(year, teacher_uid) => {
    const data = await this.api.get('/project',{
      teacher_uid: teacher_uid, year: year
    }); 
    if (!data.data) { data.data = []}
    return data
  }

  getAllTeacherProject = async(year) => {
    const data = await this.api.get(`/project`,{
      year: year
    })
    if (!data.data) data.data = []
    return data
  }
  getSelectedTeacherPriority = async(year) => {
    const data = await this.api.get(`/priority/teacher/${year}`); 
    if (data) return data;
    else []
  }

  adminGetAllSelectedTeacherPriority = async(year, teacher_uid) => {
    const data = await this.api.get(`/priority/teacher`,{
      teacher_uid: teacher_uid, year: year
    })
    if (!data.data) data.data = []
    return data
  }

  adminGetSelectedTeacherPriority = async(year, pid) => {
    const data = await this.api.get(`/priority/teacher`,{
      year: year, pid: pid
    })
    if (!data.data) data.data = []
    return data
  }

  createTeacherPriority = async(priority) => {
    return await this.api.post(`/priority/teacher/batch`, 
      priority
    )
  }

  getNthPriority = async(year, teacher_uid, n, offset, limit) => {
    const data = await this.api.get(`/priority/student`,{
      year: year, teacher_uid: teacher_uid, top: n, offset: offset, limit: limit
    })
    if (data) return data;
    else []
  }

  getAllProjectStudent = async (year, offset, limit) => {
    const data = await this.api.get(`/project/record/student/${year}`,{
      offset: offset, limit: limit
    });
    if (!data.data) data.data = [];
    return data
  }

  deleteTeacherPriority = async(year, pid) => {
    return await this.api.delete(`/priority/teacher/${year}/${pid}`);
  }
  // stage four

  getAllProjectRecord = async(year, offset, limit) => {
    const data = await this.api.get(`/project/record`, {
      year: year, offset: offset, limit: limit
    });
    if (data) return data;
    else []
  }

  patchProjectRecord = async(student_uid, year, pid) => {
    return await this.api.patch(`/project/record/${student_uid}/${year}`,{
      "pid": pid, "passed": false
    });
  }
  getSelfProjectByStudentId = async(student_uid, year) => {
    const data = await this.api.get(`/project/record`,{
      keyword: student_uid, year: year
    })
    if (!data.data) data.data = [];
    return data;
  }
  getProjectByPid = async(pid, year) => {
    const data = await this.api.get(`/project/record`,{
      pid: pid, year: year
    })
    if (!data.data) data.data = []
    return data
  }

  distribute = async(year) => {
    return await this.api.post(`/priority/match/${year}`)
  }

}