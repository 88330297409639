//import moment from 'moment';
export default class ProfileService {
  constructor(api) {
    this.api = api;
  }

  getPublicProfile(id) {
    return this.api.get(`/user/profile/public/${id}`);
  }
  getPrivateProfile(id) {
    return this.api.get(`/user/profile/private/${id}`);
  }

  saveProfile(uid, profile) {
    return this.api.patch(`/user/${uid}`, profile);
  }

  getTeacherOptions(query, offset, limit) {
    var request = query
    if(limit > 0) {
      request["offset"] = offset
      request["limit"] = limit
    }else{
      request['retrieve_all'] = true
    }
    return this.api.get(`/user/profile/public/search`, request);
  }
}
