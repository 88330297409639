<template>
  <div id="master">
    <div class="profile-public-container">
      <div class="profile-pic-container">
        <img :src="this.photo" :onerror="`this.src='${this.defaultProfile}'`" />
      </div>
      <div class="profile-public-info-container">
        <div class="profile-public-label">基本資料</div>
        <div class="public-info">
          <div class="info-item">
            <div class="item-label">姓名</div>
            <div class="item-content">{{ academicData.name }}</div>
          </div>
          <div class="info-item">
            <div class="item-label">學號</div>
            <div class="item-content">{{ academicData.uid }}</div>
          </div>
          <div class="info-item">
            <div class="item-label">年級</div>
            <div class="item-content">{{ grade }}</div>
          </div>
        </div>
        <div class="public-info">
          <div class="info-item">
            <div class="item-label item-label-longer">入學年份</div>
            <div class="item-content">{{ academicData.entry_year }}</div>
          </div>
          <div class="info-item">
            <div class="item-label item-label-longer">畢業年限</div>
            <div class="item-content">
              {{ academicData.graduate_year_limit }}
            </div>
          </div>
          <div class="info-item">
            <div class="item-label item-label-longer">休學狀態</div>
            <div class="item-content">{{ dropStatus }}</div>
          </div>
        </div>
      </div>
    </div>

    <BasicCourse />
    <PaperOutcome />
    <EnglishTest />
    <ResearchTest grade="mba" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import config from '../../../config';
import moment from 'moment';

import BasicCourse from '@/components/Academy/Master/BasicCourse';
import PaperOutcome from '@/components/Academy/Master/PaperOutcome';
import EnglishTest from '@/components/Academy/Master/EnglishTest';
import ResearchTest from '@/components/Academy/Master/ResearchTest';
export default {
  name: 'academy-master',
  components: {
    BasicCourse,
    PaperOutcome,
    EnglishTest,
    ResearchTest,
  },
  data() {
    return {
      uid: '',
      defaultProfile: require("../../assets/defaultProfile.jpg"),
      publicProfile: {}
      // academic: {},
    };
  },
  computed: {
    ...mapGetters(['currentUID', 'academicData']),
    dropStatus() {
      const statusMapping = Object.freeze({
        student: '在學',
        drop: '休學',
      });
      return statusMapping[this.academicData.dropout_status];
    },
    grade() {
      const dates = moment().diff(
        moment(this.academicData.entry_year + '-09-12'),
        'days',
      );
      const grade = Math.floor(dates / 365) + 1;
      return grade;
    },
    photo() {
      return `${config.BASEURL}/image/${this.publicProfile.photo}`;
    }
  },
  methods: {
    ...mapActions(['setAcademicData', 'setCourseData']),
    async getAcademic() {
      const academic = await this.$services.academic
        .getAcademic('mba', this.uid)
        .catch(e => {
          console.error(e);
          this.$router.push(`/Profile/${this.uid}`);
        });
      if (academic.proposal_details) {
        academic.proposal_details.forEach((proposal, idx) => {
          academic.proposal_details[idx].confirm =
            proposal.confirm == null ? null : proposal.confirm == false ? 0 : 1;
          academic.proposal_details[idx].date = moment(proposal.date).format(
            'YYYY-MM-DD',
          );
        });
      } else {
        academic.proposal_details = [];
      }
      if (academic.degree_exam_details) {
        academic.degree_exam_details.forEach((exam, idx) => {
          academic.degree_exam_details[idx].confirm =
            exam.confirm == null ? null : exam.confirm == false ? 0 : 1;
          academic.degree_exam_details[idx].date = moment(exam.date).format(
            'YYYY-MM-DD',
          );
          academic.degree_exam_details[idx].deadline = moment(
            exam.deadline,
          ).format('YYYY-MM-DD');
        });
      } else {
        academic.degree_exam_details = [];
      }
      this.setAcademicData(academic);
    },
    async getProfile() {
      const publicProfile = await this.$services.profile.getPublicProfile(
        this.uid,
      );
      this.publicProfile = publicProfile;
    }
  },
  mounted() {
    this.uid = this.$route.params.id;
    this.getAcademic();
    this.getProfile();
  },
};
</script>

<style scoped lang="scss">
#master {
  padding: 33px 16% 33px 8%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-weight: bold;

  height: 100vh;
  overflow: scroll;

  .page-head {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    .page-title {
      color: var(--v-pageTitle-base);
      font-size: 25px;
      font-weight: bold;
      text-align: left;
    }
  }

  .profile-public-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    width: 100%;
    height: 200px;
    margin-top: 30px;

    .profile-pic-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      height: 100%;

      & img {
        width: auto;
        height: 100%;
        border-radius: 24px;
        box-shadow: 1px 1px 6px 0 rgba(0, 0, 0, 0.06);
      }
    }

    .profile-public-info-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: space-between;

      background-color: var(--v-white-base);
      width: 100%;
      height: 100%;
      border-radius: 24px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.06);
      padding: 35px 28px;
      margin-left: 20px;

      position: relative;

      .profile-public-label {
        width: 160px;
        height: 40px;
        position: absolute;
        top: -20px;

        display: flex;
        justify-content: center;
        align-items: center;

        background-color: var(--v-green-base);
        color: var(--v-white-base);
      }

      .public-info {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        .info-item {
          width: 100%;
          height: 40px;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;

          .item-label {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 80px;
            height: 35px;
            color: var(--v-white-base);
            background-color: var(--v-green-base);
            border-radius: 12px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }

          .item-label-longer {
            width: 100px;
          }

          .item-content {
            margin-left: 16px;
            font-weight: bold;
            color: var(--v-darkGrey2-base);
            text-align: left;
          }
        }
      }
    }
  }
}
</style>
