<template>
<div id="project">
  <div class="project-container">
		<div class="project-tag">{{project.year}} 學年度專題內容 (1)</div>
    <div class="project-main">
        <v-img v-if="!isEditMode" max-height="30" max-width="30"
          :src="require('../assets/red-edit.svg')"
          class="project-edit" 
          @click="changeToEdit()"
        />
				<v-icon v-else class="project-done" :size="33" 
				style="color: var(--v-blue-base);"
				@click="changeToDone()"
				>mdi-checkbox-marked-circle</v-icon>
        <div class="project-item project-item-small">
						<div class="project-title">指導教授</div>
						<input :disabled="!isEditMode" v-model="project.teacher_name" class="project-content">
				</div>
        <div class="project-item project-item-small">
          <div class="project-title">專題名稱</div>
					<input :disabled="!isEditMode" v-model="project.title" class="project-content">
        </div>
        <div class="project-item project-item-extra-big">
          <div class="project-title">專題內容</div>
					<div class="project-markdown-container">
						<!-- <v-md-editor 
							left-toolbar="undo redo | link image code"
							class="project-markdown" 
							v-model="project.markdown" :mode="mode"
							@change="markdownChange"></v-md-editor> -->
					</div>
				</div>
    </div>
	</div>
</div>
</template>
<script>
export default {
	name: 'project',
  async mounted(){
    this.project = await this.$services.historyProject.getSingleHistoryProject(this.$route.params.pid)
  },
	data() {
		return {
			project:{},
			isEditMode: false,
			mode:"preview",
		};
	},
  methods:{
    changeToEdit(){
      this.isEditMode = true;
			this.mode = "editable";
    },
		changeToDone(){
			this.isEditMode = false;
			this.mode = "preview";
      this.$services.historyProject.patchSingleHistoryProject(this.$route.params.pid, this.project)
		},
		markdownChange(text, html){
			this.text = text;
			this.htmlText = html;
		}
  },
};
</script>
<style scoped>
#project{
	padding: 33px 10% 33px 8%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100vh;
}
.project-container{
  width: 90%;
	height: 730px;
  align-items:flex-start;
  justify-content:flex-start;
  flex-wrap:nowrap;
  margin-bottom: 50px;
}
.project-edit{
  height: 30px;
  display: flex;
  align-self: flex-end;
}
.project-edit:hover{
  cursor: pointer;
}
.project-done{
	height: 30px;
	margin-right:5px;
	display: flex;
  align-self: flex-end;
}
.project-done:hover{
	cursor: pointer;
}
.project-tag{
  position: relative;
	width: 250px;
  height: 35px;
  background-color: #9ed3fd;
  padding: 7px;
  color: #ffffff;
  font-weight: bold;
  letter-spacing: 1.28px;
  margin-bottom: -15px;
  margin-left: 25px;
  z-index: 10;
}
.project-main{
	width:100%;
	height: 100%;
  background: #ffffff;
  z-index: -1;
  padding: 30px 20px 30px 20px;
  display: flex;
  flex-direction: column;
  border-radius: 24px;
}
.project-item{
  display: flex;
  flex-direction: row;
  margin: 10px;
}
.project-item-small{
  height: 40px;
}
.project-item-big{
  height: 120px;
}
.project-item-extra-big{
	height: 500px;
}
.project-title{
  background-color: #9ed3fd;
  width: 100px;
  height: 100%;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1.02px;
  text-align: center;
  color: #ffffff;
  padding: 10px;
  border-radius: 12px 0px 0px 12px;
  background-color: #9ed3fd;
}
.project-content{
  width: 800px;
  height: 100%;
  margin: 0px 0 10px 15px;
  padding: 0px 0px 0px 5px;
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  color: var(--v-darkGrey-base);
  flex-wrap:wrap;
  word-wrap:normal;
}
.project-content:focus{
	outline-width: thin;
  outline-color: var(--v-blue-base); 
  border:2px solid var(--v-blue-base);
}
.project-markdown{
	margin-top: -8px;
	height:100%;
}
.project-markdown-container{
  height: 100%;
  margin: 7px 0 6px 24px;
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  color: #6d6d6d;
  flex-wrap:wrap;
  word-wrap:normal;
  width: 100%
}
</style>